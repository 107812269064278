import pattiPhoto from "pngs/Patti.png";

const profile = {
  employeeId: "927482",
  firstName: "Patricia",
  lastName: "Williamson",
  company: "Tex Earnhardt Ford",
  supervisor: "Julianne Moore",
  position: "Product Specialist",
  managementLevel: "10",
  timeType: "Full Time",
  fte: "100.00%",
  location: "Austin, TX",
  city: "Austin",
  state: "TX",
  hireDate: "02/09/2020",
  lengthOfService: "2 years, 1 Day",
  timeInPosition: "1 year, 2 months, 12 days",
  phone: "+1 (512) 389-9567",
  email: "Patti.Williamson@TexEF.com",
  photo: pattiPhoto,
};

export { profile };

import React from "react";
import styled from "styled-components";
import "styled-components/macro";
import { applications } from "data";

const AppIconPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme, which }) => {
    // console.log("AppIcon", which, theme.carbon.app[which]?.color);
    return theme.carbon.app[which]?.color;
  }};
`;
const AppInitials = styled.div`
  color: ${({ theme }) => theme.carbon.color.element.appIcon.color};
  position: absolute;
  z-index: 2;
`;
export const AppName = styled.div`
  color: ${({ theme }) => theme.carbon.color.element.appName.color};
  font-family: ${({ theme }) => theme.carbon.element.appIcon.text.family};
  font-weight: 600;
`;
const IconContainer = styled.div`
  font-family: ${({ theme }) => theme.carbon.element.appIcon.text.family};
  font-size: ${({ theme }) => theme.carbon.element.appIcon.text.size};
  font-weight: ${({ theme }) => theme.carbon.element.appIcon.text.weight};
  width: ${({ theme }) => theme.carbon.element.appIcon.width};
  height: ${({ theme }) => theme.carbon.element.appIcon.height};
  ${AppInitials} {
    border-radius: ${({ theme }) => theme.carbon.element.appIcon.radius};
    background-image: url($appIconBackground);

    position: relative;
  }
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  word-wrap: break-word;
  position: relative;
  background: ${({ group }) => tiles[group]};
`;

const AppIconRaw = ({ className, which, hideName = false }) => {
  const app = applications.filter((app) => app.name === which)[0];
  return (
    <div className={className}>
      <IconContainer group={app.group}>
        <AppInitials>{app?.initials}</AppInitials>
        <AppIconPattern which={which} />
      </IconContainer>
      <AppName>{!hideName && app?.name}</AppName>
    </div>
  );
};

const AppIcon = styled(AppIconRaw)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SearchAppIconRaw = ({ className, which, appInitials, label }) => {
  return (
    <div className={className}>
      <IconContainer>
        <AppInitials>{appInitials}</AppInitials>
        <AppIconPattern which={which} />
      </IconContainer>
      <AppName>{label}</AppName>
    </div>
  );
};
const SearchAppIcon = styled(SearchAppIconRaw)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const tiles = {
  crm: "#cf392b",
  "digital retailing": "#000000",
  dms: "#315c2c",
  "CDK Drive SaaS": "#315c2c",
  "embedded insurance": "#42b5e1",
  "fixed ops": "#0374dd",
  fortellis: "#6a2875",
  intelligence: "#6a2875",
  "it solutions": "#1d809a",
  wholesaling: "#000000",
};

const getTileColor = (group) => tiles[group] ?? "#ffff00";

const Product = styled(({ className, children }) => {
  if (children.length === 4) {
    return (
      <div className={className}>
        <div>{children.slice(0, 2)}</div>
        <div>{children.slice(2)}</div>
      </div>
    );
  }
  return <div className={className}>{children}</div>;
})`
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 18px;
  color: #fff;
  padding: 0 3px 0 2px;
  font-family: "Montserrat";
  letter-spacing: -0.06em;
  line-height: 18px;
  font-weight: 400;
  text-align: right;
`;

const Tile = styled(({ className, group, children }) => {
  return (
    <div className={className}>
      <Product>{children}</Product>
    </div>
  );
})`
  background: ${({ group }) => getTileColor(group.toLowerCase())};
  width: 48px;
  height: 48px;
  border-radius: 2px;
  position: relative;
`;

const Listing = styled(({ className, product }) => {
  return (
    <div className={className}>
      <Tile group={product.group}>{product.abbreviation}</Tile>
      <div>{product.name}</div>
    </div>
  );
})`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export { AppIcon, AppIconPattern, AppInitials, SearchAppIcon, Listing, Tile };

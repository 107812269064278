import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { banners } from "./banners";
import { IconClose } from "cdk-radial";
import { Close } from "./Close";
import { Container } from "./Container";
import { Wrapper } from "./Wrapper";
import { Thumbnails } from "./Thumbnails";
import { Thumbnail } from "./Thumbnail";
import { Pane } from "./Pane";

const bannerWidth = "1200px";
const paneSlidingSpeed = "0.5s";
const rotationSpeed = 1000 * 15; //1000ms * 5 = 5s
const replacementSpeed = 700; // how quick slot1 is replaced
const pauseAmount = 1000 * 10; //1000ms * 10 = 10s

export const Carousel = styled(({ className, isOpen, close }) => {
  const [containerClassName, setContainerClassName] = useState("");
  const [container, setContainer] = useState({ slot1: 0, slot2: 1 });
  const tempClass = useRef("");
  const paneArray = banners;

  const slot1 = paneArray[container.slot1];
  const slot2 = paneArray[container.slot2];

  const handleSetPane = (index) => {
    setContainerClassName("pause");
    const newSlot1 = index;
    const newSlot2 = (index + 1) % paneArray.length;
    setContainer({ slot1: newSlot1, slot2: newSlot2 });

    tempClass.current = tempClass.current === "pause" ? "move" : "pause";
    const timer = setTimeout(() => {
      tempClass.current = "move";
    }, pauseAmount);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (tempClass.current !== "pause") {
        setContainerClassName((currentCLass) => "move");
        setTimeout(() => {
          setContainer((currentContainer) => ({
            slot1: (currentContainer.slot1 + 1) % paneArray.length,
            slot2: (currentContainer.slot2 + 1) % paneArray.length,
          }));
          setContainerClassName((currentClass) => "");
        }, replacementSpeed);
      }
    }, rotationSpeed);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className={className}>
      {isOpen && (
        <>
          <Wrapper bannerWidth={bannerWidth}>
            <Close onClick={close}>
              <IconClose />
            </Close>
            <Container
              bannerWidth={bannerWidth}
              paneSlidingSpeed={paneSlidingSpeed}
              className={containerClassName}
            >
              <Pane pane={slot1} bannerWidth={bannerWidth} />
              <Pane pane={slot2} bannerWidth={bannerWidth} />
            </Container>
          </Wrapper>
          <Thumbnails>
            {paneArray.map((item, index) => (
              <Thumbnail
                key={`Thumbnail${index}`}
                className={index === container.slot1 ? "active" : ""}
                onClick={() => handleSetPane(index)}
              ></Thumbnail>
            ))}
          </Thumbnails>
        </>
      )}
    </div>
  );
})`
  margin-top: 32px;
  margin-bottom: 32px;
`;

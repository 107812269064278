const customers = [
  {
    id: "4wSBcausSCKkGIv-Z5vPK",
    firstName: "John",
    middleName: "Randolph",
    lastName: "Smith",
    preferredName: "Randy",
    language: "English",
    birthdate: "11/01/1967",
    ssn: "555-55-1234",
    cell: "(555)-555-1111",
    home: "(555)-555-2222",
    work: "(555)-555-3333",
    preferredContact: "cell",
  },
  {
    firstName: "Janet",
    lastName: "Hill",
    language: "English",
    ssn: "068-00-5831",
    preferredName: "Janet",
    phone: "(603) 480-3331",
    cell: "(576) 470-1150",
    work: "(383) 278-1557",
    preferredContact: "email",
    email: "Janet@gmail.com",
    birthdate: "3/31/1968",
    age: 54,
    id: "sGqsqYovIKZVjIionp3tb",
  },
  {
    firstName: "Henry",
    lastName: "Lee",
    language: "Spanish",
    ssn: "065-66-5318",
    preferredName: "Henry",
    phone: "(307) 456-0772",
    cell: "(628) 462-3301",
    work: "(325) 840-2812",
    preferredContact: "cell",
    email: "Henry@email.com",
    birthdate: "3/28/1991",
    age: 31,
    id: "4ORx4kWwCltj2c3bB5WD9",
  },
  {
    firstName: "Aaron",
    lastName: "Davis",
    language: "Spanish",
    ssn: "068-18-7571",
    preferredName: "Aaron",
    phone: "(448) 318-3315",
    cell: "(770) 256-7032",
    work: "(204) 086-4120",
    preferredContact: "email",
    email: "Aaron.Davis@yahoo.com",
    birthdate: "4/19/1967",
    age: 55,
    id: "hc_pCPLGoJkSMREjXcLix",
  },
  {
    firstName: "Ryan",
    lastName: "Jackson",
    language: "Spanish",
    ssn: "105-44-5501",
    preferredName: "Ryan",
    phone: "(564) 848-7078",
    cell: "(275) 047-6423",
    work: "(773) 731-8860",
    preferredContact: "cell",
    email: "Ryan.Jackson@gmail.com",
    birthdate: "4/9/1973",
    age: 49,
    id: "pTPZM0W1QnKVYu5SaJI6t",
  },
  {
    firstName: "Cheryl",
    lastName: "Anderson",
    language: "English",
    ssn: "867-85-2462",
    preferredName: "Cheryl",
    phone: "(751) 786-1662",
    cell: "(685) 251-2625",
    work: "(134) 500-2212",
    preferredContact: "email",
    email: "Cheryl.Anderson@gmail.com",
    birthdate: "3/5/1991",
    age: 31,
    id: "k9SxA-iHnuSd5rPL5OW-G",
  },
  {
    firstName: "Jean",
    lastName: "Nelson",
    language: "Spanish",
    ssn: "618-17-8807",
    preferredName: "Jean",
    phone: "(038) 123-6201",
    cell: "(870) 161-7408",
    work: "(716) 403-1716",
    preferredContact: "cell",
    email: "Jean@email.com",
    birthdate: "3/30/1999",
    age: 23,
    id: "mmXQ0liKYHFmK0p-FtThf",
  },
  {
    firstName: "Samuel",
    lastName: "Taylor",
    language: "French",
    ssn: "614-42-8270",
    preferredName: "Samuel",
    phone: "(564) 588-4053",
    cell: "(351) 765-0231",
    work: "(372) 131-5041",
    preferredContact: "email",
    email: "Samuel.Taylor@gmail.com",
    birthdate: "4/13/1970",
    age: 52,
    id: "tHPWxOZG5SdqL-FydwQUo",
  },
  {
    firstName: "Kelly",
    lastName: "Miller",
    language: "English",
    ssn: "847-70-5438",
    preferredName: "Kelly",
    phone: "(265) 507-4468",
    cell: "(684) 086-4806",
    work: "(071) 076-3737",
    preferredContact: "work",
    email: "Kelly@yahoo.com",
    birthdate: "4/10/1990",
    age: 32,
    id: "kIYGL4QEGk_A3LIGh-jaO",
  },
  {
    firstName: "Carl",
    lastName: "Torres",
    language: "English",
    ssn: "507-64-0878",
    preferredName: "Carl",
    phone: "(124) 748-2460",
    cell: "(751) 243-5628",
    work: "(016) 685-0162",
    preferredContact: "cell",
    email: "Carl@gmail.com",
    birthdate: "3/31/1984",
    age: 38,
    id: "loh5sVF2_RLOjopEuuHTu",
  },
];

export { customers };

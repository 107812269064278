import styled from "styled-components";

export const Content = styled.div`
  background: yellow;
  flex-grow: 1;
  padding: 16px;
  position: relative;
  color: white;
  font-family: "Montserrat";
`;

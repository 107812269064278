import styled from "styled-components";
import { Description } from "./Description";
import { Teaser } from "./Teaser";
import { LearnMore } from "./LearnMore";

export const Info = styled(({ className, banner, lightDarkMode }) => {
  return (
    <div className={className}>
      <Teaser>{banner.teaser}</Teaser>
      <Description>{banner.description}</Description>
      <LearnMore
        text="Learn more"
        lightDarkMode={lightDarkMode}
        onClick={() => window.open(banner.url, "_blank")}
      />
    </div>
  );
})`
  background-color: ${({ theme }) =>
    theme.carbon.color.element.hero.backgroundColor};
  padding-bottom: 16px;
  min-height: 180px;
  ${LearnMore} {
    text-align: right;
  }
`;

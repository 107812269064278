import styled from "styled-components";
import { useUser } from "data/userContext";

export const HistoryIcon = styled(({ className, ...other }) => {
  const { user } = useUser();
  const entriesInHistory = user.history.length;
  return (
    <div className={className} {...other}>
      {entriesInHistory}
    </div>
  );
})`
  border: 1px solid rgba(255, 255, 255, 0.9);
  color: rgba(255, 255, 255, 0.9);
  background: #000;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  margin: 0 8px;
`;

import { useState, createContext, useContext } from "react";

const AdminSettingsContext = createContext();

const AdminSettingsProvider = ({ children }) => {
  const [adminSettings, setAdminSettings] = useState({
    useDivs: false,
    hideOpenButtons: true,
    roleId: 0,
    sheetUsesDiv: true,
    enableSidebarShortcutKeys: false,
    openIn: "sheet",
  });

  return (
    <AdminSettingsContext.Provider value={{ adminSettings, setAdminSettings }}>
      {children}
    </AdminSettingsContext.Provider>
  );
};

const useAdminSettings = () => {
  const { adminSettings, setAdminSettings } = useContext(AdminSettingsContext);

  const changeRole = (id) => {
    const newAdminSettings = { ...adminSettings };
    newAdminSettings.roleId = id;
    setAdminSettings(newAdminSettings);
  };

  return { adminSettings, setAdminSettings, changeRole };
};

export { AdminSettingsContext, AdminSettingsProvider, useAdminSettings };

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import background from "pngs/BlueCarbonBackground.png";
import { LoginCard } from "./components/LoginCard";
import styled from "styled-components";
import "styled-components/macro";

const getToken = () => {
  const tokenString = sessionStorage.getItem("user");
  const userToken = JSON.parse(tokenString);
  return userToken;
};

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  padding-left: 32px;
`;
const Logo = styled.div`
  background: #fff;
  color: #000;
  width: 94px;
  height: 94px;
  font-size: 28px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  letter-spacing: 1px;
`;
const Title = styled.div`
  font-size: 32px;
  text-transform: uppercase;
  font-family: Montserrat;
  color: #fff;
  font-weight: 700;
  letter-spacing: 2px;
`;
const LoginBody = styled.div``;
const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 120px;
  margin-top: 120px;
`;

const LoginRaw = ({ className }) => {
  const [form, setForm] = useState({});
  const [showLogin, setShowLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = getToken();
    if (userId) navigate("/app", { replace: true });
    if (!userId) setShowLogin(true);
  }, []);

  return (
    showLogin && (
      <div className={className}>
        <Container>
          <LogoBox>
            <Logo>CDK</Logo>
            <Title>Unify</Title>
          </LogoBox>
          <LoginBody>
            <LoginCard form={form} />
          </LoginBody>
        </Container>
      </div>
    )
  );
};

const Login = styled(LoginRaw)`
  display: flex;
  align-items: flex-start;

  justify-content: center;
  height: 100vh;
  background: #000;
`;

export { Login };

import React from "react";
import styled from "styled-components";
import "styled-components/macro";
import { BUTTON_VARIANTS } from "cdk-radial";
// import { UserContext } from "data/userContext";
import { CarbonButton } from "components/CarbonButton";
import { ToggleButton } from "components/ToggleButton";
import { CarbonCard, Description, Header, Footer } from "components/CCard";
import { isInArray, toggleValue } from "utils";
import { useUser } from "data/userContext";

const CardWithOpenButton = ({
  className,
  flow,
  lightDarkMode,
  favorite,
  setFavorite,
  openApplication,
}) => (
  <CarbonCard className={className}>
    <div>
      <Header>{flow.name}</Header>
      <Description>{flow.description}</Description>
    </div>
    <Footer>
      <ToggleButton
        lightDarkMode={lightDarkMode}
        state={favorite}
        toggle={setFavorite}
      />
      <CarbonButton
        text="Open"
        variant={BUTTON_VARIANTS.SECONDARY}
        onClick={openApplication}
      />
    </Footer>
  </CarbonCard>
);

const CardWithoutOpenButton = ({
  className,
  flow,
  lightDarkMode,
  favorite,
  setFavorite,
  openApplication,
}) => (
  <CarbonCard className={className} onClick={openApplication}>
    <div>
      <Header>{flow.name}</Header>
      <Description>{flow.description}</Description>
    </div>
    <Footer>
      <ToggleButton
        lightDarkMode={lightDarkMode}
        state={favorite}
        toggle={setFavorite}
      />
    </Footer>
  </CarbonCard>
);

const WorkflowCardRaw = ({
  className,
  flow,
  handleNewSheet,
  hideOpenButtons,
}) => {
  const {
    user,
    user: { lightDarkMode },
    setUser,
  } = useUser();

  const openApplication = () => {
    console.log("OPEN APP", flow);
    handleNewSheet({
      url: flow.url,
      // name: (
      //   <div style={{ textAlign: "center" }}>
      //     <p>{flow.application}</p>
      //     <p>{flow.name}</p>
      //   </div>
      // ),
      name: flow.application,
      flow: flow.name,
      image: flow?.image,
      video: flow?.video,
      width: flow?.width,
    });
  };

  // Handle Favorite button

  const favorite = isInArray({
    searchFor: flow.name,
    inArray: user.favoriteWorkflows,
  });

  const setFavorite = () => {
    toggleValue({
      value: flow.name,
      inArray: "favoriteWorkflows",
      source: user,
      setSource: setUser,
    });
  };

  if (user && !hideOpenButtons) {
    return (
      <CardWithOpenButton
        className={className}
        flow={flow}
        lightDarkMode={lightDarkMode}
        favorite={favorite}
        setFavorite={setFavorite}
        openApplication={openApplication}
      >
        <div>
          <Header>{flow.name}</Header>
          <Description>{flow.description}</Description>
        </div>
        <Footer>
          <ToggleButton
            lightDarkMode={lightDarkMode}
            state={favorite}
            toggle={setFavorite}
          />
          <CarbonButton
            text="Open"
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={openApplication}
          />
        </Footer>
      </CardWithOpenButton>
    );
  }
  if (user && hideOpenButtons) {
    return (
      <CardWithoutOpenButton
        className={className}
        flow={flow}
        lightDarkMode={lightDarkMode}
        favorite={favorite}
        setFavorite={setFavorite}
        openApplication={openApplication}
      />
    );
  }
};
const WorkflowCard = styled(WorkflowCardRaw)`
  ${({ hideOpenButtons }) => {
    const {
      user: { lightDarkMode },
    } = useUser();
    if (hideOpenButtons === true) {
      return `&:hover { cursor: pointer; background: ${
        lightDarkMode === "dark" ? "#333" : "#ccc"
      };`;
    }
  }}
`;
export { WorkflowCard };

import React, { useEffect, useState } from "react";
import { useUser } from "data/userContext";
import { themes } from "./theme";
import { theme } from "theme";
import cloneDeep from "lodash.clonedeep";
import { ThemeProvider } from "styled-components";
import "styled-components/macro";

const ThemeStore = ({ children }) => {
  const { user } = useUser();

  const initState = () => {
    theme.carbon.color = "dark";
    theme.color.primary[500].value = "#000";

    return theme;
  };

  const [myTheme, setMyTheme] = useState(initState());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userTheme = cloneDeep(theme);
    const brand = user.brand;
    const lightDarkMode = user.lightDarkMode;

    userTheme.carbon.color = themes[brand].color[lightDarkMode];
    userTheme.carbon.text = themes[brand].text;
    setMyTheme(userTheme);
    setIsLoading(false);
  }, [user]);
  return (
    !isLoading && <ThemeProvider theme={myTheme}>{children}</ThemeProvider>
  );
};
export { ThemeStore };

import React, { useState, useRef } from "react";
import styled from "styled-components";
import "styled-components/macro";
import { InlineInput, IconEdit, IconButton } from "cdk-radial";

const CustomInlineInput = styled(InlineInput)`
  label {
    padding: 4px;
    border-radius: 4px;
    height: auto;
    font-size: 14px;
    margin: 0;
  }
  input {
    font-family: Montserrat;
    font-size: 14px;
    margin: 0;
    width: 100%;
  }
  margin: 0;
  color: green;
`;
const CustomIconEdit = styled(IconEdit)`
  width: 13px;
  height: 13px;
  padding: 8px 6px;
  display: none;
  margin: 0;
`;
const Value = styled.div`
  padding: 6px;
`;
const Display = styled.div`
  display: flex;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
  gap: 4px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 14px;

  &:hover {
    border: 1px solid #c4c5c8;
  }
  &:hover ${CustomIconEdit} {
    background: #c4c5c8;
    display: block;
  }
`;
const Edit = styled.div``;

const EditableInputRaw = ({ className, name, id, label, value, onChange }) => {
  const [inputActive, setInputActive] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const inlineInput = useRef();
  const handleEdit = () => {
    setInputActive(true);
    setInputFocus(true);
  };

  return (
    <div className={className}>
      <Display
        className={inputActive === false ? "" : "hide"}
        onClick={handleEdit}
      >
        <Value>{value}</Value>
        <CustomIconEdit />
      </Display>
      <Edit className={inputActive === true ? "" : "hide"}>
        <CustomInlineInput
          label={name}
          ref={inlineInput}
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          hideLabel={true}
          isFocused={true}
          onFocus={() => inlineInput.current.select()}
          onKeyUp={(e) => {
            if (e.key === "Enter") setInputActive(false);
          }}
          onBlur={() => setInputActive(false)}
        />
      </Edit>
    </div>
  );
};

const EditableInput = styled(EditableInputRaw)`
  .hide {
    display: none;
  }
  .edit {
    display: none;
  }
  &:hover .edit {
    display: block;
  }
  margin-left: -7px;
  flex-grow: 1;
`;

export { EditableInput, Display, Edit };

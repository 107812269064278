import React from "react";
import styled from "styled-components";
import "styled-components/macro";
import {
  IconButton,
  IconHelp,
  IconAccountCircle,
  IconSettings,
  IconNotification,
  IconBubbleChart,
} from "cdk-radial";
import { useUser } from "data/userContext";
import { Search } from "components/Search";
import { HistoryIcon } from "./HistoryIcon";
import { Logo } from "./Logo";
import { Icons } from "./Icons";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const CarbonHeader = styled(
  ({ className, toggle, sideSheetHandler }) => {
    const {
      user: { lightDarkMode },
    } = useUser();
    return (
      <div className={className}>
        <Row>
          <Logo onClick={toggle}>CDK Global</Logo>
          {/* <Search /> */}
          <Icons>
            <HistoryIcon
              onClick={(event) => {
                sideSheetHandler("history", event);
              }}
            />
            <IconButton
              onClick={(event) => sideSheetHandler("help", event)}
              backgroundAppearance={lightDarkMode}
              icon={<IconHelp />}
              text="Help"
            />
            <IconButton
              onClick={(event) => sideSheetHandler("chat", event)}
              backgroundAppearance={lightDarkMode}
              icon={<IconBubbleChart />}
              text="Chat"
            />

            <IconButton
              onClick={(event) => sideSheetHandler("profile", event)}
              backgroundAppearance={lightDarkMode}
              icon={<IconAccountCircle />}
              text="Profile"
            />
            <IconButton
              onClick={(event) => sideSheetHandler("settings", event)}
              backgroundAppearance={lightDarkMode}
              icon={<IconSettings />}
              text="Settings"
            />
            {/* <IconButton
              onClick={(event) => sideSheetHandler("notifications", event)}
              backgroundAppearance={lightDarkMode}
              icon={<IconNotification />}
              text="Notification Center"
            /> */}
          </Icons>
        </Row>
      </div>
    );
  }
)`
  font-family: ${({ theme }) => {
    return theme.carbon.element.carbonHeader.text.family;
  }};
  font-size: ${({ theme }) => theme.carbon.element.carbonHeader.text.size};
  font-weight: ${({ theme }) => theme.carbon.element.carbonHeader.text.weight};
  padding: ${({ theme }) => theme.carbon.element.carbonHeader.padding};
  padding-bottom: 0;
  text-transform: ${({ theme }) => theme.carbon.element.carbonHeader.text.case};
  background: #000;
  ${() => {
    const {
      user: { lightDarkMode },
    } = useUser();
    if (lightDarkMode === "light") {
      return "background: #fff; &&& svg { fill: #1a1a1a }; &&& svg:hover { background: rgba(0,0,0,0.2); border-radius: 50px; svg { fill: black; } }";
    }
  }}
`;

import styled from "styled-components";
import { Logo } from "./Logo";
import { Content } from "./Content";
import { Headline } from "./Headline";
import { Description } from "./Description";
import { LearnMore } from "./LearnMore";

export const Pane = styled(({ className, pane, bannerWidth }) => {
  const { headline, description } = pane;
  return (
    <div className={className}>
      <Logo>
        <img src={pane.logo} alt="Logo" />
      </Logo>
      <Content>
        <Headline>{headline}</Headline>
        <Description>{description}</Description>
        <LearnMore>Learn More</LearnMore>
      </Content>
    </div>
  );
})`
  display: flex;
  width: ${({ bannerWidth }) => bannerWidth};
  flex-shrink: 0;
  ${Logo} {
    background: ${({ pane }) => pane.logoColor ?? "white"};
  }
  ${Content} {
    background: ${({ pane }) => pane.contentColor ?? "black"};
  }
`;

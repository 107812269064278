import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "styled-components/macro";
import { IconClose, BUTTON_SIZES } from "cdk-radial";
import { CustomIconButton } from "components/SideSheet";
import { CarbonButton } from "components/CarbonButton";
import { SetRole } from "components/SetRole";

const removeToken = (navigate) => {
  sessionStorage.removeItem("user");
  navigate("/");
};

const ProfilePicRaw = ({ className, person }) => {
  return (
    <div className={className}>
      <img src={person?.photo} alt="Profile" />
    </div>
  );
};
export const ProfilePic = styled(ProfilePicRaw)`
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  img {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    border: 4px solid #fff;
  }
`;

const Titles = styled.div`
  background: transparent;
`;
const ProfileSectionRaw = ({ className, person }) => {
  return (
    <div className={className}>
      <ProfilePic person={person} />
      <Titles>
        <h2>
          {person?.firstName} {person?.lastName}
        </h2>
        <h4>{person?.company}</h4>
      </Titles>
    </div>
  );
};
export const ProfileSection = styled(ProfileSectionRaw)`
  display: flex;
  flex-direction: row;
  background: linear-gradient(0deg, #fff 0%, #fff 49%, #000 50%, #000 100%);
  color: white;
  border-top: 25px solid #000;
  align-items: top;
  h2 {
    margin: 8px 0 0 0;
    font-size: 20px;
  }
  h4 {
    margin: -4px 0 0 0;
    font-weight: normal;
    font-size: 14px;
  }
`;

const Details = styled.div`
  display: grid;
  grid-template-columns: 45% 55%;
`;
const Label = styled.div`
  margin-top: 8px;
  font-size: 14px;
`;
const Value = styled.div`
  margin-top: 8px;
  font-size: 14px;
`;

export const SideHeader = styled.div`
  background: #000;
  padding: 8px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0 0;
`;
export const SideBody = styled.div`
  padding: 16px;
`;

const ProfileContentRaw = ({ className, person, toggle }) => {
  const navigate = useNavigate();
  return (
    <div className={className}>
      <SideHeader>
        <div>User Profile</div>
        <CustomIconButton
          icon={<IconClose />}
          size={BUTTON_SIZES.SMALL}
          text="Close"
          onClick={toggle}
          hideText
        />
      </SideHeader>
      <ProfileSection person={person} />
      <SideBody>
        <h2>Details</h2>
        <Details>
          <Label>Store</Label>
          <Value>{person?.store}</Value>
        </Details>
        <h2>Contact Information</h2>
        <Details>
          <Label>Phone</Label>
          <Value>{person?.phone}</Value>
          <Label>Email</Label>
          <Value>{person?.email}</Value>
        </Details>
        {/* <Label>Employee ID</Label>
          <Value>{person?.employeeId}</Value>
          <Label>Supervisor</Label>
          <Value>{person?.supervisor}</Value>
          <Label>Position</Label>
          <Value>{person?.position}</Value>
          <Label>Employee Type</Label>
          <Value>{person?.employeeType}</Value>
          <Label>Management Level</Label>
          <Value>{person?.managementLevel}</Value>
          <Label>Time Type</Label>
          <Value>{person?.timeType}</Value>
          <Label>FTE</Label>
          <Value>{person?.fte}</Value>
          <Label>Location</Label>
          <Value>
            {person?.city}, {person?.state}
          </Value>
          <Label>Hire Date</Label>
          <Value>{person?.hireDate}</Value>
          <Label>Length of Service</Label>
          <Value>{person?.lengthOfService}</Value>
          <Label>Time in Position</Label>
          <Value>{person?.timeInPosition}</Value>
        </Details>
        <h2>Contact Information</h2>
        <Details>
          <Label>Phone</Label>
          <Value>{person?.phone}</Value>
          <Label>Email</Label>
  <Value>{person?.email}</Value>
        </Details> */}
        <CarbonButton onClick={() => removeToken(navigate)} text="Logout" />
      </SideBody>
    </div>
  );
};

const ProfileContent = styled(ProfileContentRaw)`
  h2 {
    margin-bottom: 4px;
  }
  ${CarbonButton} {
    margin-left: 0;
    margin-top: 64px;
  }
`;

const ChatRaw = ({ className, toggle, user }) => {
  return (
    <div className={className}>
      <iframe
        title="Chat"
        border="0"
        src="https://app-backend-d2l6zasrpigok.azurewebsites.net/"
      ></iframe>
    </div>
  );
};

const Chat = styled(ChatRaw)`
  font-family: Montserrat;
  iframe {
    width: 426px;
    height: 90vh;
    border-radius: 16px;
  }
  ${SetRole} {
    margin: 24px 8px 8px;
    color: #aaa;
    select {
      color: #aaa;
      border-color: #aaa;
    }
  }
`;

export { Chat };

import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import styled from "styled-components";
import "styled-components/macro";
import { Login } from "pages/Login";
import { Main } from "pages/Main";
import { UserContextProvider } from "data/userContext";
import { AdminSettingsProvider } from "data/adminSettings";
import { ThemeStore } from "theme";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

const getToken = () => {
  const tokenString = sessionStorage.getItem("user");
  const userToken = JSON.parse(tokenString);
  return userToken;
};

const ProtectedRoute = ({ children }) => {
  const user = getToken();
  if (!user) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const queryClient = new QueryClient();

const App = styled(({ className }) => {
  // console.log(myTheme);
  return (
    <QueryClientProvider client={queryClient}>
      <AdminSettingsProvider>
        <UserContextProvider>
          <ThemeStore>
            <div className={`App ${className}`}>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route
                  path="/app"
                  element={
                    <ProtectedRoute>
                      <Main />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </div>
          </ThemeStore>
        </UserContextProvider>
      </AdminSettingsProvider>
    </QueryClientProvider>
  );
})`
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

export default App;

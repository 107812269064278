import { useState } from "react";
import { useNavigate } from "react-router";
import { theme } from "theme";
import {
  Input,
  Checkbox,
  CHECKBOX_LABEL_POSITIONS,
  PasswordInput,
  PrimaryButton,
} from "cdk-radial";
import { Header } from "./Header";
import { Disclaimer } from "./Disclaimer";
import { Copyright } from "./Copyright";
import { SetRole } from "components/SetRole";
import styled from "styled-components";
import "styled-components/macro";

export const LoginCard = styled(({ className, form, handler }) => {
  const [email, setEmail] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const updateEmail = (event) => {
    setEmail(event.target.value);
  };
  const updateRememberMe = (event) => {
    setRememberMe(event.target.checked);
  };

  const updatePassword = (event) => {
    setPassword(event.target.value);
  };

  const setToken = (userId, navigateTo = "/app") => {
    sessionStorage.setItem("user", JSON.stringify(userId));
    navigate(navigateTo, { replace: true });
  };

  const login = () => {
    let userId = "4"; // bob
    if (email === "julie") userId = "2";
    setToken(userId, "/app");
  };

  // Make buttons black
  theme.color.primary[500].value = "#000";

  return (
    <div className={className}>
      <Header>Sign In {/*<SetRole /> */}</Header>
      <Input
        label="Email"
        name="Email"
        id="Email"
        value={email}
        placeholder="Example@email.com"
        onChange={updateEmail}
      />
      <Checkbox
        checked={rememberMe}
        label="Remember me"
        labelPosition={CHECKBOX_LABEL_POSITIONS.AFTER}
        name="remember-me"
        onChange={updateRememberMe}
      />
      <PasswordInput
        css={`
          margin-top: 16px;
          margin-bottom: 32px;
        `}
        id="password-input-simple"
        label="Password Input"
        name="password-input-simple"
        onChange={updatePassword}
        value={password}
      />

      <PrimaryButton onClick={login} text="Sign In" />
      <Disclaimer>
        <p>Forgot your password?</p>
        <p>Forgot your Email? Please contact CDK Support.</p>
        <p>
          Not a member? To request an account, please contact your
          CDK&nbsp;Global Administrators.
        </p>
        <Copyright />
      </Disclaimer>
    </div>
  );
})`
  width: 432px;
  border: 1px solid black;
  border-radius: 8px;
  padding: 16px 16px 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #fff;
  ${SetRole} {
    font-size: 14px;
  }
`;

import React, { useState, useContext } from "react";
import styled from "styled-components";
import "styled-components/macro";
import { SideSheetHeader, SideSheetBody } from "components/SideSheet";
import {
  Checkbox,
  CHECKBOX_LABEL_POSITIONS,
  Switch,
  SWITCH_LABEL_POSITIONS,
} from "cdk-radial";
import { EditableInput } from "components/EditableInput";
import { UserContext } from "data/userContext";
import { profile } from "data";
import cloneDeep from "lodash.clonedeep";

const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const Label = styled.div`
  width: 140px;
  font-size: 14px;
`;

const getFullName = () => `${profile.firstName} ${profile.lastName}`;
const getPosition = () => `${profile.position}`;
const getEmail = () => `${profile.email}`;
const getPhone = () => `${profile.phone}`;

const SettingsRaw = ({ className, toggle }) => {
  const { user, setUser } = useContext(UserContext);
  const [position, setPosition] = useState(getPosition());
  const [email, setEmail] = useState(getEmail());
  const [phone, setPhone] = useState(getPhone());
  const [week, setWeek] = useState("Sunday");
  const [dateFormat, setDateFormat] = useState("YYY/MM/DD");
  const [numberFormat, setNumberFormat] = useState("1,000,000.00");
  const [phoneNumberFormat, setPhoneNumberFormat] = useState("(123) 456-7890");
  const [accountingEntry, setAccountingEntry] = useState(false);
  const [downloadPdfs, setDownloadPdfs] = useState(false);

  const handlePosition = (e) => setPosition(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePhone = (e) => setPhone(e.target.value);
  const handleWeek = (e) => setWeek(e.target.value);
  const handleDate = (e) => setDateFormat(e.target.value);
  const handleNumber = (e) => setNumberFormat(e.target.value);
  const handlePhoneFormat = (e) => setPhoneNumberFormat(e.target.value);
  const handleAccountingEntry = (e) => setAccountingEntry(e.target.checked);
  const handleDownloadPdfs = (e) => setDownloadPdfs(e.target.checked);

  const [checked, setChecked] = useState(
    user.lightDarkMode === "dark" ? true : false
  );

  const handleDarkModeChange = (event) => {
    const checked = event.target.checked;
    const tempUser = cloneDeep(user);

    if (checked === true) {
      tempUser.lightDarkMode = "dark";
    }
    if (checked === false) {
      tempUser.lightDarkMode = "light";
    }
    setUser(tempUser);
    setChecked(checked);
  };

  return (
    <div className={className}>
      <SideSheetHeader title="Settings" toggle={toggle} />
      <SideSheetBody>
        <h3>Settings</h3>
        {/* <Checkbox
          checked={checked}
          label="Use Dark Mode"
          labelPosition={CHECKBOX_LABEL_POSITIONS.AFTER}
          name="darkmode"
          onChange={handleDarkModeChange}
        /> */}
        <Switch
          checked={checked}
          label="Use Dark Mode"
          labelPosition={SWITCH_LABEL_POSITIONS.BEFORE}
          name="darkmode"
          onChange={handleDarkModeChange}
        />
        {/* <h4>{getFullName()}</h4>
        <Row>
          <Label>Name</Label>
          <EditableInput
            name="name"
            id="name"
            label="Name"
            value={position}
            onChange={handlePosition}
            hideLabel
          />
        </Row>
        <Row>
          <Label>Email</Label>
          <EditableInput
            name="email"
            id="email"
            label="email"
            value={email}
            onChange={handleEmail}
            hideLabel
          />
        </Row>
        <Row>
          <Label>Phone</Label>
          <EditableInput
            name="phone"
            id="phone"
            label="phone"
            value={phone}
            onChange={handlePhone}
            hideLabel
          />
        </Row>
        <h4>Locations</h4>
        <p>Tex Earnhardt Ford, Earnhardt Mazda</p>
        <h4>Formatting</h4>
        <Row>
          <Label>Week Starts on</Label>
          <EditableInput
            name="week"
            id="week"
            label="week"
            value={week}
            onChange={handleWeek}
            hideLabel
          />
        </Row>
        <Row>
          <Label>Date Format</Label>
          <EditableInput
            name="dateformat"
            id="dateformat"
            label="dateformat"
            value={dateFormat}
            onChange={handleDate}
            hideLabel
          />
        </Row>
        <Row>
          <Label>Number Format</Label>
          <EditableInput
            name="numberFormat"
            id="numberFormat"
            label="numberFormat"
            value={numberFormat}
            onChange={handleNumber}
            hideLabel
          />
        </Row>
        <Row>
          <Label>Phone Number Format</Label>
          <EditableInput
            name="phoneNumberFormat"
            id="phoneNumberFormat"
            label="phoneNumberFormat"
            value={phoneNumberFormat}
            onChange={handlePhoneFormat}
            hideLabel
          />
        </Row>
        <Row>
          <Checkbox
            checked={accountingEntry}
            label="Use Accounting Entry"
            labelPosition={CHECKBOX_LABEL_POSITIONS.AFTER}
            name="accountingEntry"
            onChange={handleAccountingEntry}
          />
        </Row>
        <Row>
          <Checkbox
            checked={downloadPdfs}
            label="Download PDFs"
            labelPosition={CHECKBOX_LABEL_POSITIONS.AFTER}
            name="downloadPdfs"
            onChange={handleDownloadPdfs}
          />
        </Row> */}
      </SideSheetBody>
    </div>
  );
};

const Settings = styled(SettingsRaw)`
  font-family: ${({ theme }) => theme.carbon.element.settings.text.family};

  h4 {
    margin: ${({ theme }) =>
      theme.carbon.element.settings.text.nameHeader.margin};
  }
`;

export { Settings };

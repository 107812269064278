import React, { createContext, useState, useContext } from "react";
import { notifications, roles } from "data";
import bobSmithProfile from "pngs/bob.smith.jpeg";
import julieJacksonProfile from "pngs/julie.jackson.jpeg";
import samProfile from "pngs/Sam.Williamson.profile.jpeg";
import cindyProfile from "pngs/Cindy.profile.jpeg";
import adminProfile from "pngs/1099admin.png";

const UserContext = createContext();

const users = [
  {
    id: 1,
    role: roles.all,
    username: "bob",
    password: "",
    employeeId: "927482",
    firstName: "Bob",
    lastName: "Smith",
    company: "Tex Earnhardt Ford",
    supervisor: "Julianne Moore",
    position: "CEO",
    managementLevel: "10",
    timeType: "Full Time",
    fte: "100.00%",
    location: "Austin, TX",
    city: "Austin",
    state: "TX",
    hireDate: "02/09/2020",
    lengthOfService: "2 years, 1 Day",
    timeInPosition: "1 year, 2 months, 12 days",
    phone: "+1 (512) 389-9567",
    email: "bob.smith@TexEF.com",
    photo: bobSmithProfile,
    apps: ["CDK Drive", "CDK Elead"],
    brand: "default",
    enabledApps: [
      "Neuron NPV",
      "CDK Drive",
      "CDK Elead",
      "CDK Service",
      "Roadster",
      "TechView",
      "Fortellis",
      "Fortellis Subscription Portal",
      "Fortellis Marketplace",
    ],
    favoriteApps: [
      "CDK Drive",
      "CDK Elead",
      "CDK Service",
      "TechView",
      "Roadster",
    ],
    favoriteWorkflows: [
      "AP Payments",
      "Vendor Management",
      "Opportunity Details",
      "Workflow Builder",
      "XChange",
      "Scheduling",
      "Repair Order",
      "Parts",
      "Trade-ins",
      "Customer Ratings",
      "Agents",
      "RO Dashboard",
    ],

    lightDarkMode: "dark",
    messages: [],
    notifications: notifications,
    removeWorkflows: [],
    history: [],
  },
  {
    id: 2,
    role: roles.accounting,
    username: "julie",
    password: "",
    employeeId: "927443",
    firstName: "Julie",
    lastName: "Jackson",
    company: "Tex Earnhardt Ford",
    supervisor: "Margret Greene",
    position: "Accountant",
    managementLevel: "10",
    timeType: "Full Time",
    fte: "100.00%",
    location: "Austin, TX",
    city: "Austin",
    state: "TX",
    hireDate: "05/01/2018",
    lengthOfService: "4 years, 1 Day",
    timeInPosition: "3 year, 5 months, 15 days",
    phone: "+1 (512) 389-9212",
    email: "julie.jackson@TexEF.com",
    photo: julieJacksonProfile,
    apps: ["CDK Drive", "CDK Elead"],
    brand: "default",
    enabledApps: ["CDK Drive", "CDK Elead"],
    favoriteApps: ["CDK Drive"],
    favoriteWorkflows: ["AP Payments", "Purchase Order", "Opportunity Details"],
    favorites: {
      apps: ["CDK Drive"],
      workflows: ["AP Payments", "Purchase Order", "Opportunity Details"],
    },
    lightDarkMode: "dark",
    messages: [],
    notifications: notifications,
    removeWorkflows: [],
    history: [],
  },

  {
    id: 3,
    role: roles.service,
    username: "cindym",
    password: "",
    employeeId: "927443",
    firstName: "Cindy",
    lastName: "Montgommery",
    company: "Tex Earnhardt Ford",
    supervisor: "Margret Greene",
    position: "Service Manager",
    managementLevel: "10",
    timeType: "Full Time",
    fte: "100.00%",
    location: "Austin, TX",
    city: "Austin",
    state: "TX",
    hireDate: "05/01/2018",
    lengthOfService: "4 years, 1 Day",
    timeInPosition: "3 year, 5 months, 15 days",
    phone: "+1 (512) 389-9212",
    email: "julie.jackson@TexEF.com",
    photo: cindyProfile,
    apps: ["CDK Drive", "CDK Elead"],
    brand: "default",
    enabledApps: ["CDK Drive", "CDK Elead"],
    favoriteApps: ["CDK Drive"],
    favoriteWorkflows: ["AP Payments", "Purchase Order", "Opportunity Details"],
    favorites: {
      apps: ["CDK Drive"],
      workflows: ["AP Payments", "Purchase Order", "Opportunity Details"],
    },
    lightDarkMode: "dark",
    messages: [],
    notifications: notifications,
    removeWorkflows: [],
    history: [],
  },

  {
    id: 4,
    role: roles.sales,
    username: "samw",
    password: "",
    employeeId: "927443",
    firstName: "Sam",
    lastName: "Williamson",
    company: "Tex Earnhardt Ford",
    supervisor: "Margret Greene",
    position: "Salesperson",
    managementLevel: "10",
    timeType: "Full Time",
    fte: "100.00%",
    location: "Austin, TX",
    city: "Austin",
    state: "TX",
    hireDate: "05/01/2018",
    lengthOfService: "4 years, 1 Day",
    timeInPosition: "3 year, 5 months, 15 days",
    phone: "+1 (512) 389-9212",
    email: "julie.jackson@TexEF.com",
    photo: samProfile,
    apps: ["CDK Drive", "CDK Elead"],
    brand: "default",
    enabledApps: ["CDK Drive", "CDK Elead"],
    favoriteApps: ["CDK Drive"],
    favoriteWorkflows: ["AP Payments", "Purchase Order", "Opportunity Details"],
    favorites: {
      apps: ["CDK Drive"],
      workflows: ["AP Payments", "Purchase Order", "Opportunity Details"],
    },
    lightDarkMode: "dark",
    messages: [],
    notifications: notifications,
    history: [],
    removeWorkflows: [],
  },
  {
    id: 5,
    role: roles["1099 Admin"],
    username: "1099admin",
    password: "",
    employeeId: "000183",
    firstName: "1099",
    lastName: "Admin",
    company: "DMS Modernization Track 2",
    store: "DMS Modernization Track 2 - 1099",
    supervisor: "Margret Greene",
    position: "Salesperson",
    managementLevel: "10",
    timeType: "Full Time",
    fte: "100.00%",
    location: "Austin, TX",
    city: "Austin",
    state: "TX",
    hireDate: "05/01/2018",
    lengthOfService: "4 years, 1 Day",
    timeInPosition: "3 year, 5 months, 15 days",
    phone: "",
    email: "admin1099@drivemod.com",
    photo: adminProfile,
    apps: ["CDK Drive", "CDK Elead"],
    brand: "default",
    enabledApps: ["CDK Drive", "CDK Elead"],
    favoriteApps: ["CDK Drive"],
    favoriteWorkflows: ["AP Payments", "Purchase Order", "Opportunity Details"],
    removeWorkflows: [
      "Accounts Receivable",
      "Financial Statements",
      "Purchase Order",
      "Parts",
      "Scheduling",
    ],
    favorites: {
      apps: ["CDK Drive"],
      workflows: ["AP Payments", "Purchase Order", "Opportunity Details"],
    },
    lightDarkMode: "dark",
    messages: [],
    notifications: notifications,
    history: [],
  },
];

const getToken = () => {
  const tokenString = sessionStorage.getItem("user");
  const userToken = JSON.parse(tokenString);
  return userToken;
};

const fetchUser = () => {
  const userId = getToken();
  const user = users.filter(({ id }) => id.toString() === userId)[0];
  return user;
};

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(users[0]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const { user, setUser } = useContext(UserContext);
  const setUserById = (id) => {
    setUser(users[id]);
  };
  return { user, setUser, setUserById };
};

export { UserContext, UserContextProvider, users, useUser };

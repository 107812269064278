import styled from "styled-components";

export const LogoSlot = styled(({ className, banner, lightDarkMode }) => {
  return <div className={className}></div>;
})`
  ${({ lightDarkMode, banner }) => {
    if (lightDarkMode === "dark") {
      return `
        background-color: ${banner.logoDarkBackgroundColor};
        background-image: url(${banner.logoDark});
        color: #000;
         `;
    }
    if (lightDarkMode === "light") {
      return `
        background-color: ${banner.logoLightBackgroundColor};
        background-image: url(${banner.logoLight});
        color: #fff;
         `;
    }
  }}
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
`;

import styled from "styled-components";

export const Teaser = styled.div`
  font-family: ${({ theme }) => {
    return theme.carbon.element.hero.teaser.fontFamily;
  }};
  font-size: ${({ theme }) => theme.carbon.element.hero.teaser.fontSize};
  font-weight: ${({ theme }) => theme.carbon.element.hero.teaser.fontWeight};
  color: ${({ theme }) => theme.carbon.element.hero.teaser.color};
  padding: ${({ theme }) => theme.carbon.element.hero.teaser.padding};
`;

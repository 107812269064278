import styled from "styled-components";
import { IconButton, IconClose } from "cdk-radial";

export const Close = styled(({ className, ...other }) => (
  <div className={className}>
    <IconButton className="IB" text="close" icon={<IconClose />} {...other} />
  </div>
))`
  position: absolute;
  top: 32px;
  right: 32px;
  ${({ lightDarkMode }) => {
    if (lightDarkMode === "dark") {
      return "&&& svg { fill: #fff;";
    }
  }}
`;

import React, { useEffect, useState, useRef, useId } from "react";
import styled from "styled-components";
import "styled-components/macro";

import { Backplane, BackplaneBody } from "components/Backplane";
import { BackplaneMain } from "components/CarbonTabs";
import { CarbonHeader } from "components/CarbonHeader";
import { Help } from "components/Help";
import { HeroContainer } from "components/Hero";
import { History } from "components/History";
import { Loader, Dialog, Checkbox, RadioGroup } from "cdk-radial";
import { nanoid } from "nanoid";
import { NotificationCenter } from "components/NotificationCenter";
import { Profile } from "components/Profile";
import { Chat } from "components/Chat";
import { ReactComponent as MobileSwitch } from "svgs/MobileSwitch.svg";
import { ReactComponent as NeuronBug } from "svgs/Nbug.svg";
import { ReactComponent as WeatherConditions } from "svgs/WeatherConditions.svg";
import { Row } from "components/Layout";
import { Settings } from "components/Settings";
import { Sheet } from "components/Sheet";
import { SideSheet } from "components/SideSheet";
import { SetRole } from "components/SetRole";
import { useToggle } from "utils";
import { useUser } from "data/userContext";
import { useAdminSettings } from "data/adminSettings";
import { userRoles } from "data";
import { AdminDialog } from "components/AdminDialog";
import { GlobalHotKeys } from "react-hotkeys";
import DriveOnWindows from "pngs/DriveOnWindowsDesktop.png";
import { useStore } from "data/store";

export const Main = styled(({ className }) => {
  const [sideSheetOpen, setSideSheetOpen] = useState(null);
  // const [sideSheetOpen, toggleSideSheet] = useToggle();
  const [urlLoaded, setUrlLoaded] = useState(false);
  const [sheetOpen, toggleSheet] = useToggle(null);
  const [activeApplication, setActiveApplication] = useState("");
  const [heroOpen, setHeroOpen] = useState(true);
  const [sideSheetContent, setSideSheetContent] = useState("");
  const [url, setUrl] = useState("");

  const [openSheets, setOpenSheets] = useState([]);
  const [currentSheet, setCurrentSheet] = useState("");

  const [adminDialogOpen, setAdminDialogOpen] = useState(false);
  // const [enableShortcutKeys, setEnableShortcutKeys] = useState('')

  const { user } = useUser();
  const { adminSettings, setAdminSettings, changeRole } = useAdminSettings();
  const iframeRefs = useRef({});
  const sheetRef = useRef();

  const toggleSideSheet = (value) => {
    setSideSheetOpen(value);
  };

  const closeHero = () => setHeroOpen(false);

  const isDriveVisible = useStore((state) => state.isDriveVisible);
  const setIsDriveVisible = useStore((state) => state.setIsDriveVisible);

  const handleNewSheet = (app) => {
    console.log("HISTORY PUSH", app);
    const id = nanoid();
    const newSheets = [
      ...openSheets,
      {
        id,
        url: app.url,
        image: app?.image,
        video: app?.video,
        width: app?.width,
      },
    ];
    if (app.name === "CDK Drive" && app?.type === "app") {
      setIsDriveVisible(true);
    } else {
      if (adminSettings.openIn === "window") {
        window.open(app.image, "_blank", "height=800,width=1280");
      }
      if (adminSettings.openIn === "tab") {
        window.open(app.image, "_blank");
      }
      if (adminSettings.openIn === "sheet") {
        setOpenSheets(newSheets);
        setCurrentSheet(id);
        toggleSheet();
      }
    }
    console.log("USER HISTORY PUSH", app);
    user.history.push({
      id,
      name: app.name,
      flow: app?.flow,
      image: app?.image,
      video: app?.video,
    });
    setUrl(app.url);
  };

  const sideSheetHandler = (which, event) => {
    if (sideSheetOpen) {
      // console.log("SideSheet is open");
    }
    if (!sideSheetOpen) {
      // console.log("SideSheet is closed");
    }
    const content = {
      chat: <Chat />,
      history: (
        <History
          key="side-history"
          toggleSideSheet={() => toggleSideSheet(false)}
          toggleSheet={toggleSheet}
          setUrl={setUrl}
          openSheetFromHistory={openSheetFromHistory}
        />
      ),
      help: <Help key="side-help" toggle={() => toggleSideSheet(false)} />,
      profile: (
        <Profile
          key="side-profile"
          user={user}
          toggle={() => toggleSideSheet(false)}
          roles={userRoles}
          adminSettings={adminSettings?.roleId}
        />
      ),
      settings: (
        <Settings key="side-settings" toggle={() => toggleSideSheet(false)} />
      ),
      notifications: (
        <NotificationCenter
          key="side-nc"
          toggle={() => toggleSideSheet(false)}
        />
      ),
    };
    setSideSheetContent(content[which]);
    // setSideSheetOpen(!sideSheetOpen);
    if (sideSheetOpen) {
      event.stopPropagation();
      setSideSheetOpen(false);
      // console.log("SideSheet is open");
    }
    if (!sideSheetOpen) {
      event.stopPropagation();
      setSideSheetOpen(true);
      // console.log("SideSheet is closed");
    }
  };

  const sideSheetRef = useRef();

  const openSheetFromHistory = (id) => {
    setCurrentSheet(id);
    toggleSideSheet(false);
    if (sheetOpen === false) toggleSheet();
  };

  // Admin Settings ------------------

  const handleOpen = () => {
    setAdminDialogOpen(true);
  };

  const handleClose = () => {
    setAdminDialogOpen(false);
  };
  const handleAdminRadio = ({ event, field }) => {
    const newAdminSettings = { ...adminSettings, [field]: event.target.value };
    setAdminSettings(newAdminSettings);
  };
  const handleAdminCheckbox = ({ event, field }) => {
    const newAdminSettings = {
      ...adminSettings,
      [field]: event.target.checked,
    };
    // newAdminSettings.hideOpenButtons = event.target.checked;
    setAdminSettings(newAdminSettings);
  };

  const hideOpenButtonsHandler = (event) => {
    const newAdminSettings = { ...adminSettings };
    newAdminSettings.hideOpenButtons = event.target.checked;
    setAdminSettings(newAdminSettings);
  };

  const handleSheetUsesDiv = (event) => {
    const newAdminSettings = { ...adminSettings };
    newAdminSettings.sheetUsesDiv = event.target.checked;
    setAdminSettings(newAdminSettings);
  };
  const handleSidebarShortcutKeys = (event) => {
    const newAdminSettings = { ...adminSettings };
    newAdminSettings.enableSidebarShortcutKeys = event.target.checked;
    setAdminSettings(newAdminSettings);
  };

  const keyMap = {
    ADMIN: "ctrl+shift+p",
    HELP: "shift+?",
    HISTORY: "h",
    NOTIFICATIONS: "n",
    PROFILE: "p",
    SETTINGS: "s",
    HIDEDRIVE: "d",
    MINIMIZE: "x",
  };
  const keyHandlers = {
    ADMIN: () => {
      handleOpen();
    },
    HELP: (event) => {
      if (adminSettings.enableSidebarShortcutKeys === true) {
        if (sideSheetOpen) setSideSheetOpen(false);
        sideSheetHandler("help", event);
      }
    },
    HISTORY: (event) => {
      if (adminSettings.enableSidebarShortcutKeys === true) {
        if (sideSheetOpen) setSideSheetOpen(false);
        sideSheetHandler("history", event);
      }
    },
    NOTIFICATIONS: (event) => {
      if (adminSettings.enableSidebarShortcutKeys === true) {
        if (sideSheetOpen) setSideSheetOpen(false);
        sideSheetHandler("notifications", event);
      }
    },
    PROFILE: (event) => {
      if (adminSettings.enableSidebarShortcutKeys === true) {
        if (sideSheetOpen) setSideSheetOpen(false);
        sideSheetHandler("chat", event);
        // sideSheetHandler("profile", event);
      }
    },
    SETTINGS: (event) => {
      if (adminSettings.enableSidebarShortcutKeys === true) {
        if (sideSheetOpen) setSideSheetOpen(false);
        sideSheetHandler("settings", event);
      }
    },
    HIDEDRIVE: () => {
      setIsDriveVisible(false);
      if (sheetOpen) toggleSheet();
    },
    MINIMIZE: () => {
      if (adminSettings.enableSidebarShortcutKeys === true) {
        if (sheetOpen) toggleSheet();
      }
    },
  };

  useEffect(() => {
    const clickHandler = (event) => {
      if (
        sideSheetOpen === true &&
        sideSheetRef.current?.contains(event.target) === false
      ) {
        setSideSheetOpen(false, event);
      }
    };
    window.addEventListener("click", clickHandler);
    return () => window.removeEventListener("click", clickHandler);
  }, [sideSheetOpen]);

  const handleOpenIframe = (toggle) => {
    toggle();
  };

  return (
    <div className={`App ${className}`}>
      <Container>
        <GlobalHotKeys
          keyMap={keyMap}
          handlers={keyHandlers}
          allowChanges={true}
        />
        <Backplane>
          <CarbonHeader
            open={sheetOpen}
            toggle={toggleSheet}
            sideSheetHandler={sideSheetHandler}
            openSheetFromHistory={openSheetFromHistory}
          ></CarbonHeader>

          <BackplaneBody>
            <Row>
              <Welcome>Welcome to CDK Unify</Welcome>
            </Row>

            <HeroContainer isOpen={heroOpen} close={closeHero} />
            <BackplaneMain
              toggleSheet={() => handleOpenIframe(toggleSheet)}
              setUrl={setUrl}
              handleNewSheet={handleNewSheet}
              activeApplication={activeApplication}
              setActiveApplication={setActiveApplication}
              hideOpenButtons={adminSettings.hideOpenButtons}
            />
          </BackplaneBody>
        </Backplane>
        <Sheet>Test</Sheet>

        {/* <div
            css={`
              background: transparent;
              position: absolute;
              z-index: 3000;
              bottom: 0;
              left: 0;
              right: 0;
              top: calc(100% - 68px);
              ${sheetOpen ? "display: none;" : ""}
            `}
            onClick={toggleSheet}
          ></div> */}

        <div ref={sideSheetRef}>
          <SideSheet open={sideSheetOpen}>{sideSheetContent}</SideSheet>
        </div>
        <AdminDialog
          adminDialogOpen={adminDialogOpen}
          handleClose={handleClose}
          hideOpenButtonsHandler={hideOpenButtonsHandler}
          handleSheetUsesDiv={handleSheetUsesDiv}
          handleSidebarShortcutKeys={handleSidebarShortcutKeys}
          handleAdminCheckbox={handleAdminCheckbox}
          handleAdminRadio={handleAdminRadio}
        />
      </Container>
      {isDriveVisible && (
        <Drive>
          <img src={DriveOnWindows} alt="" />
        </Drive>
      )}
    </div>
  );
})`
  background: ${() => {
    const {
      user: { lightDarkMode },
    } = useUser();
    if (lightDarkMode === "dark") return "#000";
    else return "#fff";
  }};
  padding: 0;
  span:hover {
    cursor: pointer;
  }
  overflow: hidden;
  ${CarbonHeader} {
    grid-area: header;
  }
  ${BackplaneBody} {
    grid-area: body;
    overflow: scroll;
  }

  @media (prefers-color-scheme: dark) {
  }
`;

// Layout Helpers

const Drive = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5000;
  background: black;
`;

const FullScreen = styled.div`
  position: absolute;
  background: black;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2000;
`;

const Welcome = styled.div`
  font-family: Montserrat;
  text-transform: uppercase;
  font-weight: 700;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
`;

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

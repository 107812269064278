import React, { useState } from "react";
import styled from "styled-components";
import { useUser } from "data/userContext";
import { Tab } from "./Tab";
import { Pane } from "./Pane";
import { Panes } from "./Panes";
import { Tabs } from "./Tabs";
import { TabBackground } from "./TabBackground";
import { TabPill } from "./TabPill";

export const TabSet = styled(
  ({ className, children, defaultTab = 0, tabsWidth }) => {
    const {
      user: { lightDarkMode },
    } = useUser();
    const [selected, setSelected] = useState(defaultTab);
    const tabs = [];
    const panes = [];
    let tabIndex = 0;
    let paneIndex = 0;
    React.Children.forEach(children, (child) => {
      if (child.type.name === Tab.name) {
        const currentIndex = tabIndex;
        tabs.push(
          <Tab
            key={child.key}
            onClick={() => {
              setSelected(currentIndex);
            }}
            lightDarkMode={lightDarkMode}
          >
            {child.props.children}
          </Tab>
        );
        tabIndex += 1;
      }
      if (child.type.name === Pane.name) {
        const currentIndex = paneIndex;
        panes.push(
          <Pane
            key={`Pane-${paneIndex}`}
            selected={selected === currentIndex ? true : false}
          >
            {child.props.children}
          </Pane>
        );
        paneIndex += 1;
      }
    });
    // console.log("TABSET", lightDarkMode);
    return (
      <div className={className}>
        <Tabs
          lightDarkMode={lightDarkMode}
          count={tabs.length}
          tabsWidth={tabsWidth}
        >
          <TabBackground lightDarkMode={lightDarkMode} />
          <TabPill count={tabs.length} selected={selected} />
          {tabs}
        </Tabs>
        <Panes>{panes}</Panes>
      </div>
    );
  }
)`
  position: relative;
`;

import React from "react";
import styled from "styled-components";
import "styled-components/macro";
import { SideSheetHeader, SideSheetBody } from "components/SideSheet";
import { HistoryCard } from "./HistoryCard";
import { useUser } from "data/userContext";

const HistoryItem = styled.div`
  width: 185px;
  height: 185px;
  border: 2px solid black;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HistoryRaw = ({ className, toggleSideSheet, openSheetFromHistory }) => {
  const { user } = useUser();

  const entriesInHistory = user.history.length;

  const reversed = [...user.history].reverse();
  // console.log("REVERSED", reversed);
  return (
    <div className={className}>
      <SideSheetHeader
        title={`History (${entriesInHistory})`}
        toggle={toggleSideSheet}
      />
      <SideSheetBody>
        <Content>
          {reversed.map((item, index) => (
            <div
              key={`History ${item.id}`}
              onClick={() => {
                openSheetFromHistory(item.id);
              }}
            >
              {/* <HistoryItem>{item.name}</HistoryItem> */}
              <HistoryCard app={item} />
            </div>
          ))}
        </Content>
      </SideSheetBody>
    </div>
  );
};

const History = styled(HistoryRaw)`
  font-family: Montserrat;
  h4 {
    margin-top: 24px;
  }
`;

export { History };

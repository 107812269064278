import React, { useContext } from "react";
import styled from "styled-components";
import "styled-components/macro";

import { UserContext } from "data/userContext";
import { Body } from "./Body";
import { Close } from "./Close";
import { LogoSlot } from "./LogoSlot";
import { Info } from "./Info";
import { useQuery } from "@tanstack/react-query";

export const Hero = styled(({ className, isOpen, close, banner }) => {
  const { user } = useContext(UserContext);
  const piAId = "147251";
  const piCId = "1285";
  const piHostname = "pi.pardot.com";
  const bannerSource = "https://pi.pardot.com/pd.js";

  let urlBanner = "";
  if (banner.url !== "") {
    const { isLoading, error, data } = useQuery({
      queryKey: ["theData"],
      queryFn: () =>
        fetch("https://pi.pardot.com/pd.js").then((res) => res.json()),
    });
    if (isLoading) urlBanner = "Loading...";
    if (error) urlBanner = "An error has occurred: " + error.message;
    if (!isLoading && !error) {
      console.log(data);
    }
  }

  console.log("BANNER", banner);
  return (
    user && (
      <div className={className}>
        <Close lightDarkMode={user.lightDarkMode} onClick={close} />
        <Body>
          {urlBanner === "" && (
            <>
              <LogoSlot banner={banner} lightDarkMode={user.lightDarkMode} />
              <Info banner={banner} lightDarkMode={user.lightDarkMode}></Info>
            </>
          )}
          {urlBanner !== "" && <div></div>}
        </Body>
      </div>
    )
  );
})`
  position: relative;
`;

{
  /* <script type="text/javascript">
piAId = '147251';
piCId = '1285';
piHostname = 'pi.pardot.com';
 
(function() {
     function async_load(){
           var s = document.createElement('script'); s.type = 'text/javascript';
           s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
           var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
     }
     if(window.attachEvent) { window.attachEvent('onload', async_load); }
     else { window.addEventListener('load', async_load, false); }
})();
</script> */
}

import styled from "styled-components";

export const Close = styled.button`
  position: absolute;
  top: 0px;
  right: 8px;
  z-index: 10;
  background: transparent;
  border: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  svg {
    padding-top: 2px;
    fill: #ccc;
  }
  &:hover {
    background: white;
    border-radius: 50%;
    svg {
      fill: #000;
    }
    cursor: pointer;
  }
`;

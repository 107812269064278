import React from "react";
import styled from "styled-components";
import "styled-components/macro";

const CarbonCard = styled.div`
  background-color: ${({ theme }) =>
    theme.carbon.color.element.card.background};
  border-color: ${({ theme }) => theme.carbon.color.element.card.border};
  border-radius: ${({ theme }) => theme.carbon.element.card.border.radius};
  border-style: ${({ theme }) => theme.carbon.element.card.border.style};
  border-width: ${({ theme }) => theme.carbon.element.card.border.width};
  padding: ${({ theme }) => theme.carbon.element.card.padding};
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
`;

const Description = styled.div`
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: "normal";
  color: ${({ theme }) => theme.carbon.color.element.card.description};
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-family: "Montserrat";
  padding-bottom: 8px;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 16px;
  margin-top: 16px;
`;

export { Cards, CarbonCard, Description, Header, Footer };

import carSourceDark from "pngs/CarSource-Dark.png";
import carSourceLight from "pngs/CarSource-Light.png";
import onePayDark from "pngs/OnePay-Dark.png";
import onePayLight from "pngs/OnePay-Light.png";
import saltyLightDark from "pngs/Salty-LightDark.png";
import compTrackrLightDark from "pngs/CompTrackr-LightDark.png";
import styled from "styled-components";

const TestBanner = styled(({ className }) => {
  const piAId = "147251";
  const piCId = "1285";
  const piHostname = "pi.pardot.com";

  return (<div className={className}></div>)(function () {
    function async_load() {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src =
        ("https:" == document.location.protocol ? "https://pi" : "http://cdn") +
        ".pardot.com/pd.js";
      var c = document.getElementsByTagName("script")[0];
      c.parentNode.insertBefore(s, c);
    }
    if (window.attachEvent) {
      window.attachEvent("onload", async_load);
    } else {
      window.addEventListener("load", async_load, false);
    }
  })();
})``;

const heroBanners = [
  // {
  //   id: "test-banner-1",
  //   app: "CarSource",
  //   type: "banner1",
  //   logoLight: carSourceLight,
  //   logoDark: carSourceDark,
  //   logoLightBackgroundColor: "#000",
  //   logoDarkBackgroundColor: "#fff",
  //   teaser: "",
  //   description: "",
  //   url: "https://pi.pardot.com/pd.js",
  // },
  // {
  //   id: "banner-2",
  //   app: "CarSource",
  //   type: "banner1",
  //   logoLight: carSourceLight,
  //   logoDark: carSourceDark,
  //   logoLightBackgroundColor: "#000",
  //   logoDarkBackgroundColor: "#fff",
  //   teaser:
  //     "We've noticed you might want to sync available inventory from your CDK DMS",
  //   description:
  //     "One of the industry's largest digital automobile wholesale platforms, powered by innovative integrations with your DMS and the auto ecosystem at large.",
  //   url: "",
  // },
  {
    id: "banner-3",
    app: "OnePay",
    type: "banner1",
    logoLight: onePayLight,
    logoDark: onePayDark,
    logoLightBackgroundColor: "#000",
    logoDarkBackgroundColor: "#fff",
    teaser: "We thought you might be interested",
    description:
      "CDK OnePay delivers a seamless payment experience in store, through mobile or online for your customers.",
    url: "",
  },
  {
    id: "banner-4",
    app: "Salty",
    type: "banner1",
    logoLight: saltyLightDark,
    logoDark: saltyLightDark,
    logoLightBackgroundColor: "#000",
    logoDarkBackgroundColor: "#fff",
    teaser: "We've noticed you might be intereste4d in our insurance solutions",
    description:
      "Our unique Embedded Insurance&reg; platfrom researches prices and policies across multipl carriers to provide a custom-built policy right when you need it most.",
    url: "",
  },
  {
    id: "banner-5",
    app: "CompTrackr",
    type: "banner1",
    logoLight: compTrackrLightDark,
    logoDark: compTrackrLightDark,
    logoLightBackgroundColor: "#000",
    logoDarkBackgroundColor: "#fff",
    teaser: "We've noticed you might be intereste4d in CompTrackr",
    description:
      "An integrated too that allows managers to optimize, back-test and manage compensation plans and spiffs.",
    url: "",
  },
];

export { heroBanners };

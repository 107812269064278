import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "styled-components/macro";
import { Tab } from "./Tab";
import { Pane } from "./Pane";

const CarouselTabs = styled.div`
  display: flex;
  gap: 8px;
  margin: 0 auto;
  justify-content: center;
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 40px;
`;
const CarouselTab = styled.div`
  background: #505150;
  &.selected {
    background: #ccc;
  }
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;
const CarouselPanes = styled.div``;
const CarouselPane = styled.div``;

const Carousel = styled(
  ({ className, children, defaultTab = 0, tabsWidth }) => {
    const [selected, setSelected] = useState(defaultTab);
    const tabs = [];
    const panes = [];
    let tabIndex = 0;
    let paneIndex = 0;
    React.Children.forEach(children, (child) => {
      if (child.type.name === Tab.name) {
        const currentIndex = tabIndex;
        tabs.push(
          <CarouselTab
            className={currentIndex === selected ? "selected" : ""}
            key={child.key}
            onClick={() => {
              setSelected(currentIndex);
            }}
          />
        );
        tabIndex += 1;
      }
      if (child.type.name === Pane.name) {
        const currentIndex = paneIndex;
        panes.push(
          <Pane
            key={`Pane-${paneIndex}`}
            selected={selected === currentIndex ? true : false}
          >
            {child.props.children}
          </Pane>
        );
        paneIndex += 1;
      }
    });

    useEffect(() => {
      const timer = setInterval(() => {
        let tempCurrent = selected;
        setSelected((temp) => (temp + 1) % panes.length);
      }, 1000);
      return () => clearInterval(timer);
    }, []);

    return (
      <div className={className}>
        <CarouselTabs count={tabs.length} tabsWidth={tabsWidth}>
          {tabs}
        </CarouselTabs>
        <CarouselPanes>{panes}</CarouselPanes>
      </div>
    );
  }
)`
  position: relative;
`;

export { Carousel, CarouselPane, CarouselTab, CarouselPanes, CarouselTabs };

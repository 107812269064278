import styled from "styled-components";

export const TabBackground = styled.div`
  background: ${({ lightDarkMode }) => {
    return lightDarkMode === "light" ? "black" : "white";
  }};
  border-radius: 100px;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

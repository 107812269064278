import styled from "styled-components";

export const Description = styled.div`
  font-family: ${({ theme }) =>
    theme.carbon.element.hero.description.fontFamily};
  font-size: ${({ theme }) => theme.carbon.element.hero.description.fontSize};
  font-weight: ${({ theme }) =>
    theme.carbon.element.hero.description.fontWeight};
  color: ${({ theme }) => theme.carbon.element.hero.description.color};
  padding: ${({ theme }) => theme.carbon.element.hero.description.padding};
`;

import logoCarSource from "pngs/carousel/CarSource-logo.png";
import logoSalty from "pngs/carousel/CDK-Salty-Logo.png";
import logoCompTrackr from "pngs/carousel/CompTrackr-Logo.png";
import logoOnePay from "pngs/carousel/OnePay-Logo.png";
import logoCdkDrive from "pngs/carousel/Logo-cdk-drive.png";

export const banners = [
  // {
  //   headline:
  //     "We've noticed you might want to sync available inventory from your CDK DMS",
  //   description:
  //     "One of the industry's largest digital automobile wholesale platforms, innovative integrations with your DMS and the auto ecosystem at large.",

  //   logo: logoCarSource,
  // },
  {
    headline:
      "We've noticed you might be interested in Additional flexibility in making secure supplier payments with the introduction of Virtual Card Payments",
    description:
      "An integrated tool that allows managers to optimize, back-test, and manage compensation plans and spiffs.",

    logo: logoCdkDrive,
  },
  {
    headline: "We thought you might be interested",
    description:
      "CDK OnePay delivers a seamless payment experience in store, through mobile or online for your customers",
    logo: logoOnePay,
  },
  {
    headline:
      "We've noticed you might be interested in our insurance solutions",
    description:
      "Our unique Embedded Insurance platform researches prices and policies across multiple carriers to provide a custom-built policy right when you need it most.",

    logo: logoSalty,
  },

  // {
  //   headline: "We've noticed you might be interested in CompTrackr",
  //   description:
  //     "An integrated tool that allows managers to optimize, back-test and manage compensation plans and spiffs.",

  //   logo: logoCompTrackr,
  // },
];

import styled from "styled-components";
import { AppIcon, Listing, Tile, AppName } from "components/AppIcon";
import { IconButton, IconClose } from "cdk-radial";
import { useUser } from "data/userContext";

const Workflow = styled.div`
  margin-top: 12px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
`;

const SmallClose = styled(IconButton)`
  transform: scale(0.5);
  margin-top: -20px;
  margin-right: -20px;
`;

export const HistoryCard = styled(
  ({ className, app, flow, lightDarkMode, removeHandler }) => {
    // console.log("APP HISTORY CARD", app);
    const { user, setUser } = useUser();
    const handleRemoveHistory = (event, id) => {
      event.stopPropagation();
      //   console.log("REMOVE HISTORY", id, user.history);
      const temp = JSON.parse(JSON.stringify(user));
      temp.history = temp.history.filter((item) => item.id !== id);
      //   console.log("TEMP HISTORY", temp.history, temp);
      setUser(temp);
    };

    return (
      <div className={className}>
        <CardHeader>
          <AppIcon which={app.name} />
          <SmallClose
            icon={<IconClose />}
            text=""
            onClick={(event) => {
              handleRemoveHistory(event, app.id);
            }}
          />
        </CardHeader>

        <Workflow>{app?.flow}</Workflow>
      </div>
    );
  }
)`
  padding: 16px;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  background: #f7f7f7;
  color: black;

  ${AppIcon} ${Listing} {
    color: black;
  }
  ${AppIcon} ${AppName} {
    color: black;
  }

  &:hover {
    background: #e6e6e6;
    border-color: #ccc;
  }
`;

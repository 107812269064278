import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import "styled-components/macro";
import { Tab } from "./Tab";
import { TabSet } from "./TabSet";
import { Pane } from "./Pane";
// import { Tab, TabSet, Pane } from "./NewCarbonTabs";
import { ApplicationsList } from "components/ApplicationsList";
import { WorkflowsList } from "components/WorkflowsList";
import { applications, workflows } from "data";
import { UserContext } from "data/userContext";

const tabs = {
  myCarbon: "My Unify",
  applications: "Applications",
  workflows: "Workflows",
};

const BackplaneTabsRaw = ({
  className,
  toggleSheet,
  setUrl,
  handleNewSheet,
  hideOpenButtons,
}) => {
  // const { currentTab, setCurrentTab } = useTabs();
  const [userApplications, setUserApplications] = useState([]);
  // const [userApplications, setUserApplications] = useState(applications);
  const [userWorkflows, setUserWorkflows] = useState([]);
  // const [userWorkflows, setUserWorkflows] = useState(workflows);
  const [onlyUserApplications, setOnlyUserApplications] =
    useState(applications);
  const [onlyUserWorkflows, setOnlyUserWorkflows] = useState(workflows);
  const { user } = useContext(UserContext);

  const applicationsSort = (a, b) => {
    const A = a.name.toLowerCase();
    const B = b.name.toLowerCase();
    if (A > B) return 1;
    if (A < B) return -1;
    return 0;
  };

  // Process favorites lists
  useEffect(() => {
    // const newUserApplications = cloneDeep(userApplications);
    // const newUserWorkflows = cloneDeep(userWorkflows);
    const newUserWorkflows = [];
    const newUserApplications = [];
    const newOnlyUserApplications = [];
    const newOnlyUserWorkflows = [];

    // Applications are now stored in roles object
    // console.log("BackplaneMain", user);
    if (user) {
      applications.forEach((app) => {
        if (user.role.apps.includes(app.name)) {
          app.enabled = true;
          newUserApplications.push(app);
        }
      });
      // console.log("User", user);
      workflows.forEach((flow) => {
        if (user.role.apps.includes(flow.application)) {
          if (user.role.name === "All") {
            // console.log("push flow", flow);
            newUserWorkflows.push(flow);
          }
          if (user.role.name !== "All") {
            if (flow.domain === "") {
              newUserWorkflows.push(flow);
            }
            if (flow.domain !== "" && flow.domain === user.role.domain) {
              newUserWorkflows.push(flow);
            }
          }
        }
      });
      newUserApplications.forEach((app) => {
        if (user.favoriteApps.includes(app.name)) {
          app.favorfite = true;
          newOnlyUserApplications.push(app);
        } else {
          app.favorite = false;
        }
      });
      newUserWorkflows.forEach((flow) => {
        if (user.favoriteWorkflows.includes(flow.name)) {
          flow.favorite = true;
          newOnlyUserWorkflows.push(flow);
        } else {
          flow.favorite = false;
        }
      });
      // console.log("BackplaneMain :: newUserWorkflows", newUserWorkflows);

      setUserApplications(newUserApplications.sort(applicationsSort));
      setUserWorkflows(newUserWorkflows);
      setOnlyUserApplications(newOnlyUserApplications.sort(applicationsSort));
      setOnlyUserWorkflows(newOnlyUserWorkflows);
    }
  }, [user]);

  return (
    user && (
      <TabSet tabsWidth="450px" defaultTab={1}>
        {Object.keys(tabs).map((tab, index) => (
          <Tab key={`Tab${index}`}>{tabs[tab]}</Tab>
        ))}

        <Pane id={tabs.myCarbon}>
          <ApplicationsList
            apps={onlyUserApplications}
            openSheet={toggleSheet}
            setUrl={setUrl}
            handleNewSheet={handleNewSheet}
            hideOpenButtons={hideOpenButtons}
          />
          <WorkflowsList
            flows={onlyUserWorkflows}
            openSheet={toggleSheet}
            setUrl={setUrl}
            hideOpenButtons={hideOpenButtons}
            handleNewSheet={handleNewSheet}
          />
        </Pane>
        <Pane id={tabs.applications}>
          <ApplicationsList
            apps={userApplications}
            openSheet={toggleSheet}
            setUrl={setUrl}
            handleNewSheet={handleNewSheet}
            hideOpenButtons={hideOpenButtons}
          />
        </Pane>
        <Pane id={tabs.workflows}>
          <WorkflowsList
            flows={userWorkflows}
            openSheet={toggleSheet}
            setUrl={setUrl}
            hideOpenButtons={hideOpenButtons}
            handleNewSheet={handleNewSheet}
          />
        </Pane>
      </TabSet>
    )
  );
};

const BackplaneMain = styled(BackplaneTabsRaw)``;

export { BackplaneMain };

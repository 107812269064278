import styled from "styled-components";
// import { Tab, Pane, Carousel } from "components/CarbonTabs";
import { heroBanners } from "data";
import { Hero } from "./Hero";
import { Carousel } from "components/Carousel";

export const HeroContainer = styled(({ className, isOpen, close }) => {
  return (
    isOpen && (
      <div className={className}>
        <Carousel isOpen={isOpen} close={close} />
        {/* <Carousel tabsWidth="108px">
          {heroBanners.map((banner, index) => (
            <Tab key={`banner-${index}`} />
          ))}
          {heroBanners.map((banner, index) => (
            <Pane key={`pane-${index}`}>
              <Hero banner={banner} isOpen={isOpen} close={close} />
            </Pane>
          ))}
        </Carousel> */}
      </div>
    )
  );
})``;

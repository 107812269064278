import styled from "styled-components";

export const Tab = styled.div`
  color: ${({ lightDarkMode }) => {
    // console.log("TAB", lightDarkMode);
    return lightDarkMode === "light" ? "white" : "black";
  }};
  /* color: ${({ lightDarkMode }) =>
    lightDarkMode === "light" ? "black" : "black"}; */
  padding: 12px 0;
  text-align: center;
  position: relative;
`;
Tab.name = "Tab";

import cdkDriveLandingPage from "pngs/Drive_Landing_Page.png";
import cdkDriveApPaymentsImage from "pngs/AP-Payments-workflow.png";
import cdkDriveVendorManagement from "pngs/Vendor-Management-Workflow.png";
import cdkEleadLandingPage from "pngs/Elead_Landing_Page.png";
import cdkEleadOpportunity from "pngs/Opportunity-Details-Screen.png";
import cdkEleadWorkflow from "pngs/CDK-Elead-Workflow-Builder-Workflow.png";
import cdkEleadXchange from "pngs/Xchange-Customer-List-workflow.png";
import cdkRoadsterLandingPage from "pngs/Roadster_Landing_Page.png";
import cdkRoadsterCurrentEmployees from "pngs/Roadster-Current-Employees.png";
import cdkServiceLandingPage from "pngs/Service_Landing_Page.png";
import cdkServicePartsWorkflow from "pngs/Service-Parts-Workflow.png";
import cdkServiceRoDashboard from "pngs/Service-RO-Dashboard-Workflow.png";
import cdkServiceSchedulingWorkflow from "pngs/Service-Scheduling-Workflow.png";
import cdkTechViewLandingPage from "pngs/TechView_Landing_Page.png";
import saltyApplication from "pngs/Salty-main-app.png";
import saltyCustomerQuote from "pngs/Salty-Customer-Quote.png";
import cdkCommonAdmin from "pngs/CDK-Common-Admin.png";
import cosa from "pngs/COSA-Application.png";
import cvrApplication from "pngs/CVR-Application.png";
import cvrMessageCenter from "pngs/CVR-Message-Center.png";
import techViewApplication from "pngs/TechView-Application.png";
import roadsterCustomers from "pngs/WF-Roadster-Customers.png";
import roadsterReports from "pngs/WF-Roadster-Reports.png";
import roadsterShowroom from "pngs/WF-Roadster-Showroom.png";
import roadsterSupport from "pngs/WF-Roadster-Support.png";
import roadsterTradeins from "pngs/WF-Roadster-Tradeins.png";
import demoVideo from "videos/demoVideoTrimmed.mp4";
import neuronNpv from "pngs/Neuron-NPV.png";
import cdkDriveApInvoice from "pngs/AP-invoice.png";
// import cdkRoDashboard2 from "pngs/RO-Dashboard-2.png";
import cdk1099 from "pngs/1099-year-end.png";
import EleadTodayView from "pngs/EleadTodayView.png";
import EleadQuickSearchMax from "pngs/EleadQuickSearchMax.png";
import EleadOrganizerMax from "pngs/EleadOrganizerMax.png";
import CheetahSaaSDMSMax from "pngs/CheetahSaaSDMSMax.png";
import RoadsterNewAdminMax from "pngs/RoadsterNewAdminMax.png";
import RoadsterCustomersMax from "pngs/RoadsterCustomersMax.png";
import RoadsterStorefrontMax from "pngs/RoadsterStorefrontMax.png";
import RoadsterPricingMax from "pngs/RoadsterPricingMax.png";
import RoadsterCustomerCheckinMax from "pngs/RoadsterCustomerCheckinMax.png";
import EleadAddCustomer from "pngs/EleadAddCustomer.png";
import EleadDesklogMax from "pngs/EleadDesklogMax.png";
import FortellisMarketPlace from "pngs/FortellisMarketplace.png";
import FortellisSubs from "pngs/FortellisSubs.png";
import FOrtellisLanding from "pngs/FortellisLandingMax.png";

import Feb2023DriveWorkflowMenu1099 from "pngs/updates-Feb162023/Drive-Workflow-Menu-1099.png";
import Feb2023DriveWorkflowMenuApInvoice from "pngs/updates-Feb162023/Drive-Workflow-Menu-AP-Invoice.png";
import Feb2023DriveWorkflowMenuApPayments from "pngs/updates-Feb162023/Drive-Workflow-Menu-AP-Payments.png";
import Feb2023ELeadAddProspect from "pngs/updates-Feb162023/eLead-Add-Prospect.png";
import Feb2023ELeadCRM from "pngs/updates-Feb162023/eLead-CRM.png";
import Feb2023ELeadDailyOrganizer from "pngs/updates-Feb162023/eLead-Daily-Organizer.png";
import Feb2023ELeadDesklog from "pngs/updates-Feb162023/eLead-Desklog.png";
import Feb2023ELeadSearch from "pngs/updates-Feb162023/eLead-Search.png";
import Feb2023ELeadToday from "pngs/updates-Feb162023/eLead-Today.png";
import Feb2023FortellisAppManagement from "pngs/updates-Feb162023/Fortellis-App-Management.png";
import Feb2023ServiceAdmin from "pngs/updates-Feb162023/Service-Admin.png";
import Feb2023CDKDriveSaaS from "pngs/updates-Feb162023/CDK-Drive-SaaS.png";
import Feb2023CDKService from "pngs/updates-Feb162023/CDK-Service.png";
import Feb2023CommonAdmin from "pngs/updates-Feb162023/Common-Admin.png";
const applications = [
  {
    name: "CDK Drive",
    initials: "DV",
    description:
      "The industry leading dealer management solution. Access your desktop Drive applications here.",
    // url: "drive.redcarbon.site",
    url: "drive.redcarbon.site",
    favorite: false,
    enabled: true,
    content: "This will be the Drive Application.",
    image: cdkDriveLandingPage,
    group: "dms",
    type: "app",
  },
  {
    name: "CDK Drive SaaS",
    initials: "DV",
    description:
      "A flexible, powerful dealer management system (DMS) tailored to multiple dealership locations",
    // url: "drive.redcarbon.site",
    url: "drive.redcarbon.site",
    favorite: false,
    enabled: true,
    content: "This will be the Drive Application.",
    // image: CheetahSaaSDMSMax,
    image: Feb2023CDKDriveSaaS,
    width: "1280px",
    group: "dms",
    type: "app",
  },
  {
    name: "CDK Elead",
    initials: "CRM",
    description:
      "Easily view, manage, and analyze customer data to build strong relationships and close sales",
    // url: "elead.redcarbon.site",
    url: "elead.redcarbon.site",
    favorite: false,
    enabled: true,
    // image: cdkEleadLandingPage,
    image: Feb2023ELeadDesklog,
    width: "1280px",
    group: "crm",
  },
  {
    name: "CDK Service",
    initials: "SV",
    description:
      "Quickly schedule service appointments for one store or the entire dealer group from a single BDC",
    // url: "service.redcarbon.site",
    url: "service.redcarbon.site",
    favorite: false,
    enabled: true,
    content: "This will be CDK Service",
    // image: cdkServiceLandingPage,
    image: Feb2023CDKService,
    width: "1280px",
    group: "fixed ops",
  },

  {
    name: "Common Admin",
    initials: "CA",
    description: "Manage CDK roles and permissions",
    // url: "commonadmin.redcarbon.site",
    url: "commonadmin.redcarbon.site",
    favorite: false,
    enabled: false,
    // image: cdkCommonAdmin,
    image: Feb2023CommonAdmin,
    width: "1280px",
    group: "it solutions",
  },

  {
    name: "Roadster",
    initials: "RD",
    description:
      "The industry's first omnichannel vehicle-buying process with dealers at its heart",
    url: "roadster.redcarbon.site",
    favorite: true,
    enabled: true,
    image: cdkRoadsterLandingPage,
    group: "digital retailing",
  },
  {
    name: "Fortellis",
    initials: "FT",
    description:
      "Fortellis is a technology platform that allows the automotive industry to leverage, build, innovate and integrate apps and workflows to transform business",
    url: "roadster.redcarbon.site",
    favorite: true,
    enabled: true,
    image: FOrtellisLanding,
    width: "1280px",
    group: "fortellis",
  },
];

const workflows = [
  {
    application: "CDK Drive SaaS",
    description: "Manage all 1099s in one place",
    domain: "Accounting",
    enabled: true,
    favorite: false,
    name: "1099",
    url: "drive.redcarbon.site/accounting/ap",
    image: Feb2023DriveWorkflowMenu1099,
    width: "1280px",
  },

  {
    application: "CDK Drive SaaS",
    description: "Manage all invoices in one place",
    domain: "Accounting",
    enabled: true,
    favorite: false,
    name: "AP Invoice",
    url: "drive.redcarbon.site/accounting/ap",
    image: Feb2023DriveWorkflowMenuApInvoice,
    width: "1280px",
  },

  {
    application: "CDK Drive SaaS",
    description: "Integrated electronic invoice payment",
    domain: "Accounting",
    enabled: false,
    favorite: false,
    name: "AP Payments",
    url: "drive.redcarbon.site/accounting/ap",
    image: Feb2023DriveWorkflowMenuApPayments,
    width: "1280px',",
  },
  {
    application: "CDK Elead",
    description: "Create, view, and manage workflows.",
    domain: "",
    enabled: false,
    favorite: false,
    name: "Workflow",
    url: "elead.redcarbon.site/opportunity",
    image: Feb2023ELeadCRM,
    width: "1280px",
  },
  {
    application: "CDK Elead",
    description: "The sales lifecycle of your customer, all in one place",
    domain: "",
    enabled: false,
    favorite: false,
    name: "Add Prospect",
    url: "elead.redcarbon.site/opportunity",
    image: Feb2023ELeadAddProspect,
    width: "1280px",
  },
  {
    application: "CDK Elead",
    description: "The sales lifecycle of your customer, all in one place",
    domain: "",
    enabled: false,
    favorite: false,
    name: "Desklog",
    url: "elead.redcarbon.site/opportunity",
    // image: EleadDesklogMax,
    image: Feb2023ELeadDesklog,
    width: "1280px",
  },
  {
    application: "CDK Elead",
    description: "The sales lifecycle of your customer, all in one place",
    domain: "",
    enabled: false,
    favorite: false,
    name: "Daily Organizer",
    url: "elead.redcarbon.site/opportunity",
    // image: EleadOrganizerMax,
    image: Feb2023ELeadDailyOrganizer,
    width: "1280px",
  },
  {
    application: "CDK Elead",
    description: "The sales lifecycle of your customer, all in one place",
    domain: "",
    enabled: false,
    favorite: false,
    name: "Search",
    url: "elead.redcarbon.site/opportunity",
    // image: EleadQuickSearchMax,
    image: Feb2023ELeadSearch,
    width: "1280px",
  },
  {
    application: "CDK Elead",
    description: "The sales lifecycle of your customer, all in one place",
    domain: "",
    enabled: false,
    favorite: false,
    name: "Today",
    url: "elead.redcarbon.site/opportunity",
    // image: EleadTodayView,
    image: Feb2023ELeadToday,
    width: "1280px",
  },

  {
    application: "Roadster",
    description: "Administrative overview and functions",
    domain: "",
    enabled: false,
    favorite: false,
    name: "Admin Launch",
    url: "roadster.redcarbon.site/tradeins",
    image: RoadsterNewAdminMax,
    width: "1280px",
  },
  {
    application: "Roadster",
    description: "Trade-in and appraisal",
    domain: "",
    enabled: false,
    favorite: false,
    name: "Storefront",
    url: "roadster.redcarbon.site/tradeins",
    image: RoadsterStorefrontMax,
    width: "1280px",
  },
  {
    application: "Roadster",
    description: "Trade-in and appraisal",
    domain: "",
    enabled: false,
    favorite: false,
    name: "Customers",
    url: "roadster.redcarbon.site/tradeins",
    image: RoadsterCustomersMax,
    width: "1280px",
  },
  {
    application: "Roadster",
    description: "Trade-in and appraisal",
    domain: "",
    enabled: false,
    favorite: false,
    name: "Pricing",
    url: "roadster.redcarbon.site/tradeins",
    image: RoadsterPricingMax,
    width: "1280px",
  },
  {
    application: "Roadster",
    description: "Trade-in and appraisal",
    domain: "",
    enabled: false,
    favorite: false,
    name: "Customer Check-in",
    url: "roadster.redcarbon.site/tradeins",
    image: RoadsterCustomerCheckinMax,
    width: "1280px",
  },
  {
    application: "CDK Service",
    description: "Repair Order Dashboard",
    domain: "",
    enabled: false,
    favorite: false,
    name: "RO Dashboard",
    url: "service.redcarbon.site/parts",
    // image: cdkServicePartsWorkflow,
    // image: cdkServiceRoDashboard,
    image: Feb2023ServiceAdmin,
    width: "1280px",
  },
  {
    application: "Fortellis",
    description: "Discover the latest services and APIs to enhance your DMS",
    domain: "",
    enabled: false,
    favorite: false,
    name: "Marketplace",
    url: "service.redcarbon.site/parts",
    image: FortellisMarketPlace,
    width: "1280px",
  },
  {
    application: "Fortellis",
    description: "Manage your API subscriptions in one place",
    domain: "",
    enabled: false,
    favorite: false,
    name: "Subscription Portal",
    url: "service.redcarbon.site/parts",
    // image: FortellisSubs,
    image: Feb2023FortellisAppManagement,
    width: "1280px",
  },
];

const getSheetDetails = (url) => {
  const application = applications.filter((app) => url === app.url)[0];
  const workflow = workflows.filter((flow) => url === flow.url)[0];

  if (!workflow && !application) return { appName: "", sheetContent: "" };

  if (workflow)
    return {
      appName: workflow.application,
      sheetContent: `Workflow: ${workflow.domain} ${
        workflow.domain ? " | " : ""
      } ${workflow.name}`,
    };
  if (!workflow)
    return { appName: application.name, sheetContent: "Main Application" };
};

const getApplication = (appName) => {
  return applications.filter((app) => app.name === appName)[0];
};

const roles = {
  all: {
    name: "All",
    apps: [
      "Neuron NPV",
      "CDK Drive",
      "CDK Elead",
      "CDK Service",
      "COSA",
      "Common Admin",
      "Salty",
      "Connected Lot",
      "TechView",
      "Roadster",
      "Ford SMARTT",
      "New Lot Intelligence",
      "Used Lot Intelligence",
      "VehicleXchange",
      "Neuron Live Vehicle Inventory",
      "CVR",
      "Service Pricing",
      "Fortellis",
      "Fortellis Subscription Portal",
      "Fortellis Marketplace",
      "CDK Drive SaaS",
    ],
    doamin: "",
    workflows: workflows.filter((item) =>
      [
        "CDK Drive",
        "CDK Elead",
        "CDK Service",
        "COSA",
        "Common Admin",
        "Salty",
        "Connected Lot",
        "TechView",
        "Roadster",
        "Ford SMARTT",
        "New Lot Intelligence",
        "Used Lot Intelligence",
        "VehicleXchange",
        "Neuron Live Vehicle Inventory",
        "CVR",
        "Service Pricing",
      ].includes(item.application)
    ),
  },
  accounting: {
    name: "Accounting",

    apps: ["CDK Drive"],
    domain: "Accounting",
    workflows: workflows.filter((item) =>
      ["CDK Drive"].includes(item.application)
    ),
  },
  sales: {
    name: "Sales",

    apps: ["CDK Drive"],
    domain: "Sales",
    workflows: workflows.filter((item) =>
      ["CDK Drive"].includes(item.application)
    ),
  },
  service: {
    name: "Service",
    apps: ["CDK Elead", "CDK Service"],
    domain: "Service",
    workflows: workflows.filter((item) =>
      ["CDK Elead", "CDK Service"].includes(item.application)
    ),
  },
  "1099 Admin": {
    name: "1099 Admin",
    apps: ["CDK Drive", "Neuron NPV", "CDK Service"],
    domain: "Accounting",
    workflows: ["Repair Order"],
  },
};

const userRoles = [
  { id: 0, name: "All" },
  { id: 1, name: "Accounting" },
  { id: 2, name: "Sales" },
  { id: 3, name: "Service" },
  { id: 4, name: "1099 Admin" },
];

// console.log("Roles", roles);

export {
  applications,
  workflows,
  getSheetDetails,
  getApplication,
  roles,
  userRoles,
};

// Archived applications
// {
//   name: "Neuron NPV",
//   initials: "NPV",
//   description:
//     "A flexible, powerful dealer management system (DMS) tailored to multiple dealership locations",
//   // url: "drive.redcarbon.site",
//   url: "drive.redcarbon.site",
//   favorite: false,
//   enabled: true,
//   content: "This will be the Drive Application.",
//   image: neuronNpv,
// },
// {
//   name: "COSA",
//   initials: "CO",
//   description: "End-user self-sign up appointment",
//   // url: "commonadmin.redcarbon.site",
//   url: "api.connectcdk.com/api/nc-cosa-consumer-ui/v1/?subscriptionId=&cid=269",
//   favorite: false,
//   enabled: false,
//   image: cosa,
//   group: "digital retailing",
// },
// {
//   name: "Salty",
//   initials: "SLTY",
//   description: "Embedded insurance platform",
//   url: "salty.redcarbon.site",
//   favorite: false,
//   enabled: false,
//   image: saltyApplication,
//   group: "embedded insurance",
// },

// {
//   name: "TechView",
//   initials: "TV",
//   description:
//     "One view into lifecycle management services for network, cybersecurity, collaboration, and endpoint devices",
//   url: "techview.redcarbon.site",
//   favorite: true,
//   enabled: true,
//   image: techViewApplication,
//   group: "it solutions",
// },
// {
//   name: "Fortellis Subscription Portal",
//   initials: "FTP",
//   description: "Manage all your APIs in one place",
//   url: "roadster.redcarbon.site",
//   favorite: true,
//   enabled: true,
//   image: cdkRoadsterLandingPage,
//   group: "fortellis",
// },
// {
//   name: "Fortellis Marketplace",
//   initials: "FTM",
//   description: "Discover new APIs",
//   url: "roadster.redcarbon.site",
//   favorite: true,
//   enabled: true,
//   image: cdkRoadsterLandingPage,
//   group: "fortellis",
// },
// {
//   name: "CVR",
//   initials: "CVR",
//   description: "Computerized vehicle registration",
//   url: "cvr.redcarbon.site",
//   favorite: false,
//   enabled: false,
//   image: cvrApplication,
//   group: "it solutions",
// },
// {
//   name: "Connected Lot",
//   initials: "CL",
//   description: "Complete inventory management",
//   url: "connectedlot.redcarbon.site",
//   favorite: false,
//   enabled: false,
// },
// {
//   name: "Ford SMARTT",
//   initials: "FS",
//   description:
//     "Use the convenience of tablets and customized DMS to improve upon the service experience",
//   url: "fordsmartt.redcarbon.site",
//   favorite: false,
//   enabled: false,
// },
// {
//   name: "New Lot Intelligence",
//   initials: "NLI",
//   description: "Optimizie new car sales performance",
//   url: "newlotintelligence.redcarbon.site",
//   favorite: false,
//   enabled: false,
// },
// {
//   name: "Used Lot Intelligence",
//   initials: "ULI",
//   description: "Optimize used car sales performance",
//   url: "usedlotintelligence.redcarbon.site",
//   favorite: false,
//   enabled: false,
// },
// {
//   name: "VehicleXchange",
//   initials: "VXC",
//   description: "Automate retention, conquest, and service campaigns",
//   url: "vehiclexchange.redcarbon.site",
//   favorite: false,
//   enabled: false,
// },
// {
//   name: "Neuron Live Vehicle Inventory",
//   initials: "LVI",
//   description: "Real-time vehicle inventory updates",
//   url: "neuron.redcarbon.site",
//   favorite: false,
//   enabled: false,
// },
// {
//   name: "Service Pricing",
//   initials: "SP",
//   description: "Accurate parts and service information",
//   url: "servicepricing.redcarbon.site",
//   favorite: false,
//   enabled: false,
// },

// ---------------------------------------------

// Archived workflows

// {
//   application: "CDK Drive SaaS",
//   description: "Online dealer management software",
//   domain: "Accounting",
//   enabled: true,
//   favorite: false,
//   name: "CDK SaaS",
//   url: "drive.redcarbon.site/accounting/ap",
//   image: cdk1099,
// },
// {
//   application: "CVR",
//   description: "Message Center",
//   enabled: false,
//   favorite: false,
//   name: "Message Center",
//   image: cvrMessageCenter,
// },
// {
//   application: "CDK Service",
//   description: "Online and in-store appointments",
//   domain: "",
//   enabled: false,
//   favorite: false,
//   name: "Scheduling",
//   url: "service.redcarbon.site/scheduling",
//   image: cdkServiceSchedulingWorkflow,
// },
// {
//   application: "CDK Service",
//   description: "Inspect, write-up, work order, document archiving",
//   domain: "",
//   enabled: false,
//   favorite: false,
//   name: "Repair Order",
//   url: "service.redcarbon.site/ro",
//   // image: cdkRoDashboard2,
//   // image: cdkServiceRoDashboard,

//   video: demoVideo,
// },
// {
//   application: "CDK Service",
//   description: "Ordering, status and tracking",
//   domain: "",
//   enabled: false,
//   favorite: false,
//   name: "Parts",
//   url: "service.redcarbon.site/parts",
//   image: cdkServicePartsWorkflow,
// },
// {
//   application: "CDK Elead",
//   description: "Create, view and manage workflows",
//   domain: "",
//   enabled: false,
//   favorite: false,
//   name: "Workflow Builder",
//   url: "elead.redcarbon.site/workflow",
//   image: cdkEleadWorkflow,
// },

// {
//   application: "CDK Elead",
//   description: "Equity Data Mining",
//   domain: "",
//   enabled: false,
//   favorite: false,
//   name: "XChange",
//   url: "elead.redcarbon.site/xchange",
//   image: cdkEleadXchange,
// },
// {
//   application: "Roadster",
//   description: "Trade-in and appraisal",
//   domain: "",
//   enabled: false,
//   favorite: false,
//   name: "Trade-ins",
//   url: "roadster.redcarbon.site/tradeins",
//   image: roadsterTradeins,
// },
// {
//   application: "Roadster",
//   description: "Lifetime NPS and NaN",
//   domain: "",
//   enabled: false,
//   favorite: false,
//   name: "Customer Ratings",
//   url: "roadster.redcarbon.site/ratings",
//   image: roadsterCustomers,
// },
// {
//   application: "Roadster",
//   description: "Current employees",
//   domain: "",
//   enabled: false,
//   favorite: false,
//   name: "Agents",
//   url: "roadster.redcarbon.site/employees",
//   image: cdkRoadsterCurrentEmployees,
// },
// {
//   application: "Roadster",
//   description: "In-store Activities",
//   domain: "",
//   enabled: false,
//   favorite: false,
//   name: "Showroom",
//   url: "roadster.redcarbon.site/showroom",
//   image: roadsterShowroom,
// },
// {
//   application: "Roadster",
//   description: "Agent and utilization reports",
//   domain: "",
//   enabled: false,
//   favorite: false,
//   name: "Reports",
//   url: "roadster.redcarbon.site/reports",
//   image: roadsterReports,
// },
// {
//   application: "Roadster",
//   description: "On-demand training, release notes, and FAQs",
//   domain: "",
//   enabled: false,
//   favorite: false,
//   name: "Support",
//   url: "roadster.redcarbon.site/support",
//   image: roadsterSupport,
// },
// {
//   application: "Salty",
//   description: "Manage customers",
//   domain: "",
//   enabled: false,
//   favorite: false,
//   name: "Customer Quote",
//   url: "salty.redcarbon.site/customer_quote",
//   image: saltyCustomerQuote,
// },
// {
//   application: "CDK Drive",
//   description: "Manage the general ledger and chart of accounts",
//   domain: "Accounting",
//   enabled: false,
//   favorite: false,
//   name: "Vendor Management",
//   image: cdkDriveVendorManagement,
//   url: "drive.redcarbon.site/accounting/vm",
// },
// {
//   application: "CDK Drive",
//   description: "Integrated ePayments for parts, service, sales",
//   domain: "Accounting",
//   enabled: false,
//   favorite: false,
//   name: "Accounts Receivable",
//   url: "drive.redcarbon.site/accounting/ar",
// },
// {
//   application: "CDK Drive",
//   description: "PO lookup, approvals, and overrides",
//   domain: "Accounting",
//   enabled: false,
//   favorite: false,
//   name: "Purchase Order",
//   url: "drive.redcarbon.site/accounting/po",
// },
// {
//   application: "CDK Drive",
//   description: "Consolidated P&L lookup and management",
//   domain: "Accounting",
//   enabled: false,
//   favorite: false,
//   name: "Financial Statements",
//   url: "drive.redcarbon.site/accounting/fs",
// },
// {
//   application: "CDK Drive",
//   description: "Parts inventory management",
//   domain: "Parts",
//   enabled: false,
//   favorite: false,
//   name: "Parts Inventory",
//   url: "drive.redcarbon.site/parts/inventory",
// },
// {
//   application: "CDK Drive",
//   description: "Ordering from OEMs",
//   domain: "Parts",
//   enabled: false,
//   favorite: false,
//   name: "OEM Ordering",
//   url: "drive.redcarbon.site/parts/order",
// },
// {
//   application: "CDK Drive",
//   description: "Submitting warranty claims to OEMs",
//   domain: "Service",
//   enabled: false,
//   favorite: false,
//   name: "OEM Warranty",
//   url: "drive.redcarbon.site/service/warranty",
// },
// {
//   application: "Salty",
//   description: "Performance dashboard",
//   domain: "",
//   enabled: false,
//   favorite: false,
//   name: "Reports",
//   url: "salty.redcarbon.site/reports",
// },

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "styled-components/macro";
import { workflows, applications } from "data";
import { WorkflowCard } from "components/WorkflowCard";
import { ToggleButton } from "components/ToggleButton";
import { Description } from "components/CCard";
import { CarbonButton } from "components/CarbonButton";
import { CarbonCard } from "components/CCard";
import { Header } from "components/CCard";
import { Footer } from "components/CCard";
import { BUTTON_VARIANTS, IconVisibilityOff } from "cdk-radial";
import { SearchAppIcon } from "components/AppIcon";

const Title = styled.div`
  color: #000;
`;

const SearchCardRaw = ({
  className,
  which,
  appInitials,
  title,
  description,
  lightDarkMode,
  favorite,
  setFavorite,
  action,
  hideOpen,
}) => (
  <CarbonCard className={className}>
    <div>
      <Header>
        <SearchAppIcon
          which={which}
          appInitials={appInitials}
          label={
            <>
              <Title>{title}</Title>
              <Description>{description}</Description>
            </>
          }
        />
      </Header>
    </div>
    <Footer>
      <ToggleButton lightDarkMode="light" state={favorite} toggle={() => {}} />
      {hideOpen !== true && (
        <CarbonButton
          text="Open"
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={action}
        />
      )}
    </Footer>
  </CarbonCard>
);

const SearchCard = styled(SearchCardRaw)`
  background: #fff;
  border: 0;
  color: #000;
  text-transform: none;

  ${Description} {
    color: #000;
  }
  ${CarbonButton} {
    background: #fff;
    color: #000;
    border-color: #000;
  }
  ${Title} {
    font-weight: 400;
  }
  ${Description} {
    font-weight: 400;
  }
`;

const Customers = styled.div`
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  li {
    margin: 0;
    padding: 0 0 8px 16px;
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;
const Workflows = styled.div``;
const Documents = styled.div``;
const Label = styled.div`
  text-transform: none;
  font-weight: 400;
  font-size: 12px;
`;
const Value = styled.div`
  text-transform: none;
  font-weight: 400;
`;

const ResultsViewOnlyRaw = ({ className }) => {
  const workflowsToShow = [
    "Trade In",
    "Opportunity Details",
    "Deal List",
    "Purchase Orders",
    "Closed Jacket",
  ];
  const documents = [];
  return (
    <div className={className}>
      <Customers>
        <h2>Customers</h2>

        <h3>Customer Info</h3>
        <ul>
          <li>
            <Label>Name</Label>
            <Value>John Randolph Smith</Value>
          </li>
          <li>
            <Label>Preferred Name</Label>
            <Value>Randuy</Value>
          </li>
          <li>
            <Label>Language</Label>
            <Value>English</Value>
          </li>
        </ul>
        <h3>Personal ID</h3>
        <ul>
          <li>
            <Label>DOB</Label>
            <Value>**/**/**** </Value>
            <IconVisibilityOff
              css={`
                transform: scale(80%);
              `}
            />
          </li>
          <li>
            <Label>Age</Label>
            <Value>32</Value>
          </li>
          <li>
            <Label>SSN</Label>
            <Value>*** *** ****</Value>
            <IconVisibilityOff
              css={`
                transform: scale(80%);
              `}
            />
          </li>
        </ul>
        <h3>Contact Info</h3>
        <ul>
          <li>
            <Label>Preferred - Cell</Label>
            <Value>(555) 555-1212</Value>
          </li>
          <li>
            <Label>Home</Label>
            <Value>(555) 999-9900</Value>
          </li>
          <li>
            <Label>Work</Label>
            <Value>(555) 999-9900</Value>
          </li>
        </ul>
      </Customers>
      <Workflows>
        <h2>Workflows</h2>
        <SearchCard
          which="Roadster"
          appInitials="RD"
          title="Trade In"
          description="John R Smith trade in value"
          handleNewSheet={null}
          hideOpen={true}
        />
        <SearchCard
          which="CDK Elead"
          appInitials="CRM"
          title="Opportunity Details"
          description="John Randolph Smith 2021"
          handleNewSheet={null}
          // favorite={true}
          hideOpen={true}
        />
        <SearchCard
          which="CDK Elead"
          appInitials="CRM"
          title="Deal List"
          description="John Randolph Smith 2021"
          handleNewSheet={null}
          hideOpen={true}
        />
        <SearchCard
          which="CDK Drive"
          appInitials="DV"
          title="Purchase Orders"
          description="Tex Earnhardt Chandler"
          handleNewSheet={null}
          hideOpen={true}
        />
        <SearchCard
          which="CDK Drive"
          appInitials="DV"
          title="Closed Jacket 2021"
          description="John R Smith Opportunity closed"
          handleNewSheet={null}
          hideOpen={true}
        />
      </Workflows>
      <Documents>
        <h2>Documents</h2>
        <SearchCard
          which="CDK Drive"
          appInitials="DV"
          title="JohnRSmith_approval"
          description=""
          handleNewSheet={null}
          // favorite={true}
          hideOpen={true}
        />
        <SearchCard
          which="CDK Elead"
          appInitials="CRM"
          title="Detail_JohnRandolphSmith"
          description=""
          handleNewSheet={null}
          // favorite={true}
          hideOpen={true}
        />
        <SearchCard
          which="Roadster"
          appInitials="RD"
          title="documents_JohnRSmith"
          description=""
          handleNewSheet={null}
          hideOpen={true}
        />
        <SearchCard
          which="CDK Drive"
          appInitials="DV"
          title="CustomerDocument"
          description=""
          handleNewSheet={null}
          hideOpen={true}
        />
      </Documents>
    </div>
  );
};

const ResultsViewOnly = styled(ResultsViewOnlyRaw)`
  position: absolute;
  width: 80%;
  height: 600px;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
  top: 57px;
  z-index: 5000;
  color: black;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  h2 {
    text-transform: none;
    font-weight: 500;
  }
  h3 {
    text-transform: none;
    font-weight: 400;
  }
`;

export { ResultsViewOnly };

import React from "react";
import styled from "styled-components";
import "styled-components/macro";

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  font-size: 32px;
`;

export { Center };

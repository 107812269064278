import { theme } from "cdk-radial";
import backplaneDarkBackground from "pngs/BlueCarbonBackground.png";

theme.carbon = {};
theme.carbon.element = {};

// Primary brands object

const themes = {};

// Default Carbon theme
themes.default = {};
themes.default.element = {};
// If there was an OEM brand
// themes.toyota = {};

themes.default.color = {};
themes.default.color.light = {};
themes.default.color.light.element = {};
themes.default.color.dark = {};
themes.default.color.dark.element = {};
themes.default.text = {
  header: {
    font: "Montserrat",
    size: "20px",
    weight: "600",
  },
  heading: {
    text: {
      family: "Montserrat",
      weight: "600",
      case: "uppercase",
      size: "14px",
    },
    padding: "16px",
    background: "red",
  },
};

themes.default.element = {
  appSwitcher: {
    radius: "20px",
  },
  carbonHeader: {
    text: {
      family: "Montserrat",
      weight: "600",
      case: "uppercase",
      size: "14px",
    },
    padding: "16px",
    background: "black",
  },
  card: {
    border: {
      color: "rgba(255,255,255,0.3)",
      width: "2px",
      radius: "4px",
      style: "solid",
    },
    padding: "16px",
    width: {
      max: "600px",
      min: "300px",
    },
  },

  settings: {
    text: {
      family: "Montserrat",
      nameHeader: {
        margin: "16px 0 8px 0",
      },
    },
  },
  workflowCard: {
    background: "rgba(0,0,0,0.6)",
  },
};

// Application Body
themes.default.color.light.element.body = {
  background: "#fff",
};
themes.default.color.dark.element.body = {
  background: "#000",
};

// Application Card

themes.default.color.light.element.applicationCard = {
  background: "rgba(0,0,0,0.1)",
  color: "#000",
};
themes.default.color.dark.element.applicationCard = {
  background: "rgba(0,0,0,0.7)",
  color: "#fff",
};

// App Icon
themes.default.element.appIcon = {
  text: {
    family: "Montserrat",
    size: "20px",
    weight: "normal",
  },
  width: "48px",
  height: "48px",
  padding: "2px",
  radius: "2px",
};
themes.default.color.dark.element.appIcon = {
  color: "#fff",
};
themes.default.color.light.element.appIcon = {
  color: "#fff",
};

// App Name
themes.default.color.dark.element.appName = {
  color: "#fff",
};
themes.default.color.light.element.appName = {
  color: "#000",
};

// Backplane
themes.default.color.dark.element.backplane = {
  backgroundColor: "#000",

  color: "#fff",
};
themes.default.color.light.element.backplane = {
  backgroundColor: "#fff",
  color: "#000",
};

// Card

themes.default.color.light.element.card = {
  background: "#f2f2f2",
  color: "#1a1a1a",
  border: "#e6e6e6",
  description: "#1a1a1a",
};
themes.default.color.dark.element.card = {
  background: "#1a1a1a",
  color: "#f2f2f2",
  border: "#333",
  description: "#f2f2f2",
};

// Hero
themes.default.element.hero = {
  teaser: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "400",
    padding: "32px 32px 0 32px",
  },
  description: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "500",
    padding: "8px 32px 32px 32px",
  },
};
themes.default.color.light.element.hero = {
  backgroundColor: "#fff",
  teaser: {
    color: "rgba(255,255,255,0.7)",
  },
  description: {
    color: "rgba(255,255,255,0.9)",
  },
};
themes.default.color.dark.element.hero = {
  backgroundColor: "#000",
  teaser: {
    color: "rgba(0,0,0,0.7)",
  },
  description: {
    color: "rgba(0,0,0,0.9)",
  },
};

// Header Icons
themes.default.color.dark.element.icon = {
  fill: "rgba(0,0,0,0.1)",
};
themes.default.color.light.element.icon = {
  fill: "rgba(0,0,0,0.7)",
};

// Tabs
themes.default.element.tabs = {};
themes.default.element.tabs.container = {
  borderRadius: "32px",
  padding: "4px",
  width: "fit-content",
  margin: "0 auto",
  fontFamily: "Montserrat",
  fontWeight: "600",
  hoverCursor: "pointer",
};
themes.default.color.light.element.tabs = {
  container: {
    backgroundColor: "#000",
    color: "#fff",
    border: "2px solid #000",
    borderRadius: "32px",
  },
  activeTab: {
    backgroundColor: "#fff",
    color: "#000",
    border: "2px solid #000",
  },
};

themes.default.color.dark.element.tabs = {
  container: {
    backgroundColor: "#fff",
    color: "#000",
  },
  activeTab: {
    backgroundColor: "#000",
    color: "#fff",
  },
};

// Sidebar Tabs
// these tabs are always on white.

themes.default.element.sidebarTabs = {};
themes.default.element.sidebarTabs.container = {
  padding: "2px",
  width: "fit-content",
  margin: "0 auto",
  fontFamily: "Montserrat",
  fontWeight: "600",
  hoverCursor: "pointer",

  backgroundColor: "#000",
  color: "#fff",
  border: "2px solid #000",
  borderRadius: "32px",
};
themes.default.element.sidebarTabs.tab = {
  padding: "8px 16px",
};
themes.default.element.sidebarTabs.activeTab = {
  backgroundColor: "#fff",
  color: "#000",
  border: "2px solid #000",
};

theme.carbon.app = {
  "CDK Drive": { color: "rgba(65,104,60,1)" },
  "CDK Drive Saas": { color: "rgba(65,104,60,1)" },
  "CDK Elead": { color: "#D34A3D" },
  TechView: { color: "#308AA2" },
  "CDK Service": { color: "#1D82E0" },
  "Common Admin": { color: "#147DDF" },
  "Connected Lot": { color: "#428D85" },
  CVR: { color: "#244E70" },
  Fortellis: { color: "#6a2875" },
  "Ford SMARTT": { color: "#2961DC" },
  "Neuron Live Vehicle Inventory": { color: "#707383" },
  "New Lot Intelligence": { color: "#773B81" },
  Roadster: { color: "#161616" },
  Salty: { color: "#53BCE4" },
  "Service Pricing": { color: "#1C81E0" },
  "Used Lot Intelligence": { color: "#B88FAF" },
  VehicleXchange: { color: "#40683C" },
};

theme.carbon.element = themes.default.element;
theme.carbon.color = themes.default.color.dark;
theme.carbon.text = themes.default.text;

export { theme, themes };

import React from "react";
import styled, { keyframes } from "styled-components";
import "styled-components/macro";

const TOP = "68px";

const openSheet = keyframes`
  from {
    top: calc(100% - ${TOP});
  }
  to {
   top: ${TOP};
  }
`;
const closeSheet = keyframes`
  from {
    top:  ${TOP};
  }
  to {
   top:  calc(100% - ${TOP});
  }
`;

const Sheet = styled.div`
  background: white;
  position: absolute;
  max-width: 1280px;
  margin: 0 auto;

  left: 0;
  right: 0;
  bottom: 0;
  top: calc(100% - ${TOP});
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  z-index: 1000;
  iframe {
    border: 0;
  }

  &.initial {
    display: none;
  }
  &.open {
    animation: ${openSheet} 0.15s ease-in-out 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
  }
  &.closed {
    animation: ${closeSheet} 0.15s ease-in-out 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
  }
  h2 {
    margin: 0 0 16px 0;
  }
  h2:hover {
    cursor: pointer;
  }
  &.open h2:hover {
    cursor: text;
  }
`;

export { Sheet };

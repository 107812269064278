import styled from "styled-components";

export const Icons = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  min-width: 200px;
  justify-content: flex-end;
  align-items: center;
`;

import styled from "styled-components";
import { useAdminSettings } from "data/adminSettings";
import { Loader, Dialog, Checkbox, RadioGroup } from "cdk-radial";
import { SetRole } from "components/SetRole";
import { userRoles } from "data";

export const AdminDialog = styled(
  ({
    className,
    adminDialogOpen,
    handleClose,
    hideOpenButtonsHandler,
    handleSheetUsesDiv,
    handleRole,
    handleChangeRole,
    handleSidebarShortcutKeys,
    handleOpenInWindow,
    handleAdminCheckbox,
    handleAdminRadio,
  }) => {
    const { adminSettings } = useAdminSettings();
    return (
      <Dialog
        className={className}
        id="admin-1"
        isOpen={adminDialogOpen}
        onClose={handleClose}
        title="Prototype Settings"
      >
        <div>
          <Checkbox
            checked={adminSettings.sheetUsesDiv}
            label="Sheet uses a DIV instead of an iFrame"
            name="sheetDiv"
            onChange={(event) =>
              handleAdminCheckbox({ event, field: "sheetUsesDiv" })
            }
          />
          <br />
          <Checkbox
            checked={adminSettings.hideOpenButtons}
            label="Entire cards open apps"
            name="cardOpen"
            onChange={(event) =>
              handleAdminCheckbox({ event, field: "hideOpenButtons" })
            }
          />
          <br />
          <Checkbox
            checked={adminSettings.enableSidebarShortcutKeys}
            label="Enable sidesheet shortcut keys (h,n,p,s,?)"
            name="shortcutKeys"
            onChange={(event) =>
              handleAdminCheckbox({ event, field: "enableSidebarShortcutKeys" })
            }
          />
          <br />

          <RadioGroup
            className="openin"
            id="openIn"
            label="Open apps in:"
            name="open-in-action"
            onChange={(event) => handleAdminRadio({ event, field: "openIn" })}
            options={[
              {
                id: "openin-sheet",
                label: "Unify Sheet",
                value: "sheet",
              },
              {
                id: "openin-window",
                label: "New Browser Window",
                value: "window",
              },
              {
                id: "openin-tab",
                label: "New Browser Tab",
                value: "tab",
              },
            ]}
            value={adminSettings.openIn}
          />
        </div>
        <SetRole
          roles={userRoles}
          currentRoleId={adminSettings?.roleId}
          handleRole={handleRole}
        />
      </Dialog>
    );
  }
)`
  .openin {
    margin-top: 8px;
  }
  .openin div {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
`;

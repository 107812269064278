import react from "react";
import styled from "styled-components";
import "styled-components/macro";
import {} from "cdk-radial";

const CarbonButtonRaw = ({ className, inverted, ...other }) => {
  return (
    <button className={className} onClick={other.onClick}>
      {other.text}
    </button>
  );
};

const CarbonButton = styled(CarbonButtonRaw)`
  font-size: 12px;
  border-radius: 36px;
  letter-spacing: 0.8px;
  font-weight: 600;
  font-family: Montserrat;
  border: 1px solid #e6e6e6;
  color: #fff;
  background: #000;
  padding: 8px 16px;
  text-transform: uppercase;
  &:hover {
    background: #fff;
    color: #000;
    cursor: pointer;
  }
  ${({ inverted }) =>
    inverted
      ? " background: #fff; color: #000; border-color: #333; svg { fill:#fff;} "
      : ""}
`;

export { CarbonButton };

import styled from "styled-components";
import { useUser } from "data/userContext";
import { useAdminSettings } from "data/adminSettings";
import { userRoles } from "data";

export const SetRole = styled(({ className }) => {
  const { changeRole, adminSettings } = useAdminSettings();
  const { setUserById } = useUser();

  const handleRole = (event) => {
    const id = event.target.value;
    setUserById(id);
    changeRole(id);
  };

  return (
    <div className={className}>
      Role:{" "}
      <select onChange={handleRole} value={adminSettings.roleId}>
        {userRoles.map((item, index) => (
          <option key={`ROLE-${item.id}`} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
})``;

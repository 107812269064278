import styled from "styled-components";
import { Tab } from "./Tab";

export const Tabs = styled.div`
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 600;
  display: flex;
  margin: 0 auto;
  position: relative;
  width: ${({ tabsWidth }) => (tabsWidth ? tabsWidth : "100%")};

  border-radius: 32px;
  border: 1px solid black;
  /* ${({ lightDarkMode }) => {
    if (lightDarkMode === "light") {
      return "border: 1px solid black; border-radius:32px;";
    } else {
      return "border: 1px solid white; border-radius: 32px;";
    }
  }} */
  ${Tab} {
    width: calc(100% / ${({ count }) => count});
  }
  &:hover {
    cursor: default;
  }
`;

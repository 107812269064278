import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "styled-components/macro";
import { useToggle } from "utils";
import { IconButton, IconFavorite, IconFavoriteBorder } from "cdk-radial";

const ToggleButtonRaw = ({
  className,
  selected,
  state,
  toggle,
  lightDarkMode,
}) => {
  // const [state, toggleState] = useToggle(selected);
  const [selectedClassName, setSelectedClassName] = useState();

  const handler = (event) => {
    toggle();
    event.stopPropagation();
    // toggleState(!state);
  };

  useEffect(() => {
    setSelectedClassName(state ? "selected" : "");
  }, [state]);
  // console.log("ToggleButton :: state", state);
  return (
    <div className={className}>
      {state === true && (
        <IconButton
          icon={<IconFavorite />}
          onClick={handler}
          text="Favorited"
          backgroundAppearance="dark"
        />
      )}
      {state === false && (
        <IconButton
          icon={<IconFavoriteBorder />}
          onClick={handler}
          text="Not Favorited"
          backgroundAppearance="dark"
        />
      )}
    </div>
  );
};

const ToggleButton = styled(ToggleButtonRaw)`
  ${({ lightDarkMode }) => {
    // console.log("ToggleButton :: ", lightDarkMode);
    if (lightDarkMode === "light") {
      return "&&& svg { fill: black }; &&&:hover { background: rgba(0,0,0,0.2); border-radius: 50px; svg { fill: black; } }";
    }
  }}
`;

export { ToggleButton };

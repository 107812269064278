const helpDocs = [
  {
    title: "Login to Unify",
    url: "login",
    viewCount: 100,
  },
  {
    title: "Find Common Applications to launch",
    url: "common-apps",
    viewCount: 90,
  },
  {
    title: "Add Workflow to My Unify",
    url: "workflow-my-unify",
    viewCount: 80,
  },
  {
    title: "Remove Workflow from My Unify",
    url: "workflow-remove",
    viewCount: 70,
  },
  {
    title: "Locate Settings and change to/from dark mode",
    url: "dark-mode",
    viewCount: 60,
  },
  {
    title: "Hide promo banner",
    url: "promo-banner",
    viewCount: 50,
  },
  {
    title: "Open another application",
    url: "open-app",
    viewCount: 40,
  },
  {
    title: "Switch between open applications",
    url: "app-switching",
    viewCount: 30,
  },
  {
    title: "Traverse through applications vs workflows",
    url: "apps-vs-workflows",
    viewCount: 20,
  },
  {
    title: "Logout from My Unify",
    url: "logout",
    viewCount: 10,
  },
];

export { helpDocs };

import React from "react";
import styled from "styled-components";
import "styled-components/macro";
import { Header } from "components/Typography";
import { WorkflowCard } from "components/WorkflowCard";
import { AppIcon, AppIconPattern } from "components/AppIcon";
import { Cards } from "components/CCard";
import { useUser } from "data/userContext";
import { remove } from "lodash";

const AppWrapper = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.3);

  ${({ lightDarkMode }) => {
    if (lightDarkMode === "dark") {
      return "background: rgba(0, 0, 0, 0.6);";
    }
    if (lightDarkMode === "light") {
      return "background: rgba(255,255,255,1);";
    }
  }}
  padding: 16px;
  margin-bottom: 16px;
`;
// const Cards = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
//   gap: 16px;
//   margin-top: 16px;
// `;

const WorkflowsListRaw = ({
  className,
  flows,
  openSheet,
  active,
  setUrl,
  hideOpenButtons,
  handleNewSheet,
}) => {
  const user = useUser();
  const { lightDarkMode } = user.user;
  const { removeWorkflows } = user.user;
  // const {
  //   user: { lightDarkMode, removeWorkflows },
  // } = useUser();

  // Put Applications in order
  flows.sort((a, b) => {
    if (a.application > b.application) return 1;
    if (a.application < b.application) return -1;
    return 0;
  });

  // Make a new array of objects for each application

  const temp = [];
  let current = "";
  flows.forEach((flow) => {
    if (current !== flow.application) {
      temp.push({ app: flow.application });
      current = flow.application;
    }
  });

  // Process each application's flows

  temp.forEach((app) => {
    // Bucket flows by application

    const flowsInApp = flows.filter((flow) => flow.application === app.app);
    flowsInApp.sort((a, b) => {
      if (a.domain > b.domain) return 1;
      if (a.domain < b.domain) return -1;
      return 0;
    });
    const tempDomains = [];
    let currentDomain = "";
    flowsInApp.forEach((flow) => {
      if (currentDomain !== flow.domain) {
        tempDomains.push({ domain: flow.domain });
        currentDomain = flow.domain;
      }
    });
    flowsInApp.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

    // Bucket flows by domain in an application

    tempDomains.forEach((item) => {
      const flowsInDomain = flows.filter((flow) => flow.domain === item.domain);
      flowsInDomain.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      item.flows = flowsInDomain;
    });
    app.flows = flowsInApp;
    app.domains = tempDomains;
  });

  // temp now contains an ordered list of applications, domains, and flows
  // The list can just be looped to create the output HTML

  const output = [];

  temp.forEach((item, index) => {
    const app = [];
    app.push(<AppIcon key={`Workflows ${index}`} which={item.app} />);

    if (item.domains.length > 0) {
      item.domains.forEach((domain, domainIndex) => {
        app.push(
          <h4 key={`Domain ${index}-${domainIndex}}`}>{domain.domain}</h4>
        );
        const outputFlows = [];
        domain.flows.forEach((flow, flowIndex) => {
          if (removeWorkflows.length > 0) {
            if (removeWorkflows?.includes(flow.name) === false) {
              outputFlows.push(
                <WorkflowCard
                  key={`Workflow ${index}-${domainIndex}-${flowIndex}`}
                  flow={flow}
                  openSheet={openSheet}
                  active={active}
                  setUrl={setUrl}
                  hideOpenButtons={hideOpenButtons}
                  handleNewSheet={handleNewSheet}
                />
              );
            }
          } else {
            outputFlows.push(
              <WorkflowCard
                key={`Workflow ${index}-${domainIndex}-${flowIndex}`}
                flow={flow}
                openSheet={openSheet}
                active={active}
                setUrl={setUrl}
                hideOpenButtons={hideOpenButtons}
                handleNewSheet={handleNewSheet}
              />
            );
          }
        });
        app.push(
          <Cards key={`Cards ${index}-${domainIndex}`}>{outputFlows}</Cards>
        );
      });
    }
    if (item.domains.length <= 0) {
      const outputFlows = [];
      item.flows.forEach((flow, flowIndex) => {
        if (removeWorkflows.length > 0) {
          if (removeWorkflows?.includes(flow.name) === false) {
            outputFlows.push(
              <WorkflowCard
                key={`Workflow ${index}--${flowIndex}`}
                flow={flow}
                openSheet={openSheet}
                active={active}
                setUrl={setUrl}
                hideOpenButtons={hideOpenButtons}
                handleNewSheet={handleNewSheet}
              />
            );
          }
        } else {
          outputFlows.push(
            <WorkflowCard
              key={`Workflow ${index}--${flowIndex}`}
              flow={flow}
              openSheet={openSheet}
              active={active}
              setUrl={setUrl}
              hideOpenButtons={hideOpenButtons}
              handleNewSheet={handleNewSheet}
            />
          );
        }
      });
      app.push(<Cards key={`Cards ${index}`}>{outputFlows}</Cards>);
    }
    output.push(
      <AppWrapper lighDarkMode={lightDarkMode} key={`AppWrapper-${index}`}>
        {app}
      </AppWrapper>
    );
  });

  return (
    <div className={className}>
      <Header>Workflows</Header>
      {output.map((item) => item)}
    </div>
  );
};

const WorkflowsList = styled(WorkflowsListRaw)`
  padding-bottom: 32px;
  ${Cards} {
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  }
`;

export { WorkflowsList };

import React, { useContext } from "react";
import styled from "styled-components";
import "styled-components/macro";
import { IconClose, IconButton } from "cdk-radial";
import { SideSheetHeader, SideSheetBody } from "components/SideSheet";
import {
  // useTabs,
  Tab,
  // Tabs,
  TabSet,
  // TabsHeader,
  // TabsBody,
  Pane,
  // Panel,
  // withTabs,
} from "components/CarbonTabs";
import { UserContext } from "data/userContext";

const tabs = {
  notifications: "Notifications",
  messages: "Messages",
};

const Row = styled.div`
  display: flex;
`;
const Grid = styled.div`
  display: grid;
`;
const NotificationTitle = styled.h5`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
`;
const Title = styled.span``;
const Source = styled.span``;
const Timestamp = styled.span`
  font-size: 12px;
`;
const Close = styled.span``;
const Body = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

const NotificationRaw = ({ className, notification }) => {
  const { title, body, timestamp, source, actions } = notification;
  return (
    <div className={className}>
      <Grid
        css={`
          grid-template-columns: 1fr 50px 25px;
          align-items: center;
          column-gap: 16px;
        `}
      >
        <NotificationTitle>
          <Title>{title}</Title> / <Source>{source}</Source>
          <Body>{body}</Body>
        </NotificationTitle>

        <Timestamp>{timestamp}</Timestamp>
        <Close>
          <IconButton
            icon={<IconClose />}
            text="close"
            id="close"
            onClick={() => {}}
            css={`
              transform: scale(0.7);
            `}
          />
        </Close>
      </Grid>
      <Row>{actions}</Row>
    </div>
  );
};
const Notification = styled(NotificationRaw)`
  border-bottom: 1px solid #ccc;
  padding: 16px 0;
  &:hover {
    background-color: #eee;
  }
`;

const NotificationTabsRaw = ({ className }) => {
  // const { currentTab, setCurrentTab } = useTabs();
  const { user } = useContext(UserContext);

  return (
    <div className={className}>
      <TabSet tabsWidth="100%" defaultTab={0}>
        {Object.keys(tabs).map((tab, index) => (
          <Tab key={`Tab${index}`}>{tabs[tab]}</Tab>
        ))}

        <Pane id={tabs.notifications}>
          <h4>Notifications</h4>
          {user.notifications.map((item, index) => (
            <Notification notification={item} key={`notification ${index}`}>
              {item.title}
            </Notification>
          ))}
        </Pane>
        <Pane id={tabs.messages}>Messages</Pane>
      </TabSet>

      {/* <Tabs
        tabs={tabs}
        defaultTab={tabs.notifications}
        onTabSelect={(tab) => setCurrentTab(tab)}
        className={className}
      >
        <Panel id={tabs.notifications}>
          <h4>Notifications</h4>
          {user.notifications.map((item, index) => (
            <Notification notification={item} key={`notification ${index}`}>
              {item.title}
            </Notification>
          ))}
        </Panel>
        <Panel id={tabs.messages}>Messages</Panel>
      </Tabs> */}
    </div>
  );
};
const NotificationTabs = styled(NotificationTabsRaw)``;

const NotificationCenterRaw = ({ className, toggle }) => {
  return (
    <div className={className}>
      <SideSheetHeader title="Notification Center" toggle={toggle} />
      <SideSheetBody>
        <NotificationTabs />
      </SideSheetBody>
    </div>
  );
};
// const NotificationCenterMain = styled(NotificationCenterRaw)`
const NotificationCenter = styled(NotificationCenterRaw)``;

// const NotificationCenter = withTabs(NotificationCenterMain);

export { NotificationCenter };

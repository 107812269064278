import React from "react";
import styled from "styled-components";
import "styled-components/macro";
import { Header } from "components/Typography";
import { ApplicationCard } from "components/ApplicationCard";
import { Cards } from "components/CCard";

// const Cards = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
//   gap: 16px;
// `;

const ApplicationsListRaw = ({
  className,
  apps,
  openSheet,
  setUrl,
  handleNewSheet,
  hideOpenButtons,
}) => {
  return (
    <div className={className}>
      <Header>Applications</Header>
      <Cards>
        {apps.map((app, index) => (
          <ApplicationCard
            key={`Application List ${index}`}
            app={app}
            openSheet={openSheet}
            handleNewSheet={handleNewSheet}
            setUrl={setUrl}
            hideOpenButtons={hideOpenButtons}
          />
        ))}
      </Cards>
    </div>
  );
};

const ApplicationsList = styled(ApplicationsListRaw)`
  padding-bottom: 32px;
`;

export { ApplicationsList };

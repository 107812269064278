import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  &.move {
    left: ${({ bannerWidth }) => `-${bannerWidth}`};
    transition: left ${({ paneSlidingSpeed }) => paneSlidingSpeed} ease-in-out
      0s;
  }
`;

import styled from "styled-components";

export const TabPill = styled.div`
  position: absolute;
  background-color: ${({ lightDarkMode }) =>
    lightDarkMode === "light" ? "white" : "white"};
  width: calc((100% / ${({ count }) => count}) - 2px);
  border-radius: 100px;
  mix-blend-mode: difference;
  z-index: 5;
  display: block;
  top: 2px;
  left: calc(
    (100% / ${({ count }) => count} * ${({ selected }) => selected}) +
      ${({ selected }) => (selected === 0 ? "2px" : "0px")}
  );
  bottom: 2px;
  transition: left 0.1s ease-in-out;
`;

import { useState, useCallback } from "react";
import cloneDeep from "lodash.clonedeep";

const isInArray = ({ searchFor, inArray }) => {
  return inArray.includes(searchFor);
};

const toggleValue = ({ value, inArray, source, setSource }) => {
  const newSource = cloneDeep(source);
  const currentValue = isInArray({
    searchFor: value,
    inArray: source[inArray],
  });

  if (currentValue === true) {
    newSource[inArray].splice(
      newSource[inArray].findIndex((item) => item === value),
      1
    );
  }
  if (currentValue === false) {
    newSource[inArray].push(value);
  }

  setSource(newSource);
};

const useToggle = (initialValue = false) => {
  const [state, setState] = useState(initialValue);
  const toggle = useCallback(() => {
    setState((value) => {
      if (value === null) return true;
      if (value === false) return true;
      return false;
    });
  }, []);
  return [state, toggle, setState];
};
export { isInArray, toggleValue, useToggle };

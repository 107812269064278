import styled from "styled-components";

export const Thumbnail = styled.button`
  width: 12px;
  height: 12px;
  background: #505150;
  border: 0;
  border-radius: 50%;
  &.active {
    background: #ccc;
  }
`;

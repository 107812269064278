import styled from "styled-components";
import "styled-components/macro";

const Backplane = styled.div`
  color: ${({ theme }) => theme.carbon.color.element.backplane.color};
  overflow: scroll;
  height: 100vh;
  background-color: ${({ theme }) =>
    theme.carbon.color.element.backplane.backgroundColor};
  background-image: ${({ theme }) =>
    theme.carbon.color.element.backplane.backgroundImage};
  display: grid;
  grid-template-rows: 68px 1fr;
  grid-template-areas:
    "header"
    "body";

  @media (prefers-color-scheme: dark) {
  }
`;
const BackplaneBody = styled.div`
  padding: 0 16px 64px 16px;
`;
export { Backplane, BackplaneBody };

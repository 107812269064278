import React, { useState, useCallback } from "react";
import styled from "styled-components";
import "styled-components/macro";
import { SearchInput, EXPAND_BEHAVIORS } from "cdk-radial";
import { customers } from "data/search";
import { ResultsViewOnly } from "./ResultsViewOnly";

const Customer = styled.div``;

const SearchRaw = ({ className }) => {
  const [search, setSearch] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchCustomer, setSearchCustomer] = useState(null);
  const [resultsLoading, setResultsLoading] = useState(true);
  const [results, setResults] = useState("");

  let searchTimeout = null;

  const timeoutSearch = (value) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      if (value === "") {
        setResults([]);
        setResultsLoading(true);
      } else {
        const temp = customers.filter((item) => item.firstName.includes(value));
        const output = temp.map((item, index) => (
          <Customer
            key={`Customer-${item.firstName}-${index}`}
            onClick={() => setSearchCustomer(item)}
          >
            {item.firstName} {item.lastName}
          </Customer>
        ));
        setResults(output);
        setResultsLoading(false);
      }
    }, 1000);
  };

  const doSearch = useCallback((searchValue) => timeoutSearch(searchValue), []);

  const handleSearchBlur = () => {
    setShowSearchResults(false);
  };

  const handleSearchFocus = () => {
    // console.log("handle Focus");
    setShowSearchResults(true);
  };

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    doSearch(searchValue);
  };

  return (
    <div className={className}>
      <form autoComplete="off">
        <SearchInput
          clearButtonLabel="clearButtonLabel"
          expandBehavior={EXPAND_BEHAVIORS.SLIDE_LEFT}
          id="search"
          name="search"
          onChange={handleSearchChange}
          onFocus={() => handleSearchFocus()}
          onClick={handleSearchFocus}
          onBlur={handleSearchBlur}
          placeholder="Search all of CDK"
          isAlwaysExpanded={true}
          value={search}
        />
      </form>
      {showSearchResults && <ResultsViewOnly />}
    </div>
  );
};
const Search = styled(SearchRaw)`
  max-width: 800px;
  min-width: 300px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  form {
    width: 100%;
  }
`;

export { Search };

import React from "react";
import styled from "styled-components";
import "styled-components/macro";

const Header = styled.h2`
  font-family: ${({ theme }) => theme.carbon.text.header.font};
  font-size: ${({ theme }) => theme.carbon.text.header.size};
  font-weight: ${({ theme }) => theme.carbon.text.header.weight};
`;

export { Header };

import React from "react";
import styled from "styled-components";
import "styled-components/macro";
import { BUTTON_VARIANTS } from "cdk-radial";
// import { UserContext } from "data/userContext";
import { CarbonButton } from "components/CarbonButton";
import { ToggleButton } from "components/ToggleButton";
import { AppIcon, Listing, Tile } from "components/AppIcon";
import { CarbonCard, Description, Header, Footer } from "components/CCard";
import { isInArray, toggleValue } from "utils";
import { useUser } from "data/userContext";

import cloneDeep from "lodash.clonedeep";
// import appIconBackground from "pngs/CarbonAppIcon512.jpeg";

const CardWithOpenButton = ({
  className,
  app,
  lightDarkMode,
  favorite,
  setFavorite,
  label,
  action,
}) => (
  <CarbonCard className={className}>
    <div>
      <Header>
        {/* <AppIcon which={app.name} /> */}
        <Tile group={app.group ?? "crm"}>{app.name}</Tile>
      </Header>
      <Description>{app.description}</Description>
    </div>
    <Footer>
      <ToggleButton
        lightDarkMode={lightDarkMode}
        state={favorite}
        toggle={setFavorite}
      />
      <CarbonButton
        text={label}
        variant={BUTTON_VARIANTS.SECONDARY}
        onClick={action}
      />
    </Footer>
  </CarbonCard>
);

const CardWithoutOpenButton = ({
  className,
  app,
  lightDarkMode,
  favorite,
  setFavorite,
  label,
  action,
}) => (
  <CarbonCard className={className} onClick={action}>
    <div>
      <Header>
        <Listing
          product={{
            group: app.group ?? "crm",
            name: app.name,
            abbreviation: app.initials,
          }}
        />
        {/* <Tile group={app.group ?? "crm"}>{app.name}</Tile> */}
        {/* <AppIcon which={app.name} /> */}
      </Header>
      <Description>{app.description}</Description>
    </div>
    <Footer>
      <ToggleButton
        lightDarkMode={lightDarkMode}
        state={favorite}
        toggle={setFavorite}
      />
    </Footer>
  </CarbonCard>
);

const ApplicationCardRaw = ({
  className,
  app,
  handleNewSheet,
  hideOpenButtons,
}) => {
  const {
    user,
    user: { lightDarkMode },
    setUser,
  } = useUser();

  const openApplication = () => {
    handleNewSheet(app);
  };

  // Handle Enabled/Open button

  const userEnabled = user.enabledApps.includes(app.name);
  const handleTurnOnOpen = () => {
    const newUser = cloneDeep(user);
    newUser.enabledApps.push(app.name);
    setUser(newUser);
  };
  const getButtonLabelAndAction = () => {
    let label = "";
    let action = null;
    if (hideOpenButtons === true) {
      label = "";
      action = openApplication;
      return { label, action };
    }
    if (app.enabled === true) {
      label = "Open";
      action = openApplication;
      return { label, action };
    }
    if (userEnabled) {
      label = "Open";
      action = openApplication;
      return { label, action };
    }
    label = "Enable";
    action = handleTurnOnOpen;
    return { label, action };
  };
  const { label, action } = getButtonLabelAndAction();

  // Handle Favorite button

  const favorite = isInArray({
    searchFor: app.name,
    inArray: user.favoriteApps,
  });
  // console.log("AC :: ", app.name, user.favoriteApps);
  //   `AppCard :: searchFor: ${app.name} :: inArray ${user.favoriteApps} :: favorite: ${favorite} `
  // );
  const setFavorite = () => {
    toggleValue({
      value: app.name,
      inArray: "favoriteApps",
      source: user,
      setSource: setUser,
    });
  };

  if (user && !hideOpenButtons) {
    return (
      <CardWithOpenButton
        className={className}
        app={app}
        lightDarkMode={lightDarkMode}
        favorite={favorite}
        setFavorite={setFavorite}
        label={label}
        action={action}
      />
      // <CarbonCard className={className}>
      //   <div>
      //     <Header>
      //       <AppIcon which={app.name} />
      //     </Header>
      //     <Description>{app.description}</Description>
      //   </div>
      //   <Footer>
      //     <ToggleButton
      //       lightDarkMode={lightDarkMode}
      //       state={favorite}
      //       toggle={setFavorite}
      //     />
      //     <CarbonButton
      //       text={label}
      //       variant={BUTTON_VARIANTS.SECONDARY}
      //       onClick={action}
      //     />
      //   </Footer>
      // </CarbonCard>
    );
  }
  if (user && hideOpenButtons) {
    return (
      <CardWithoutOpenButton
        className={className}
        app={app}
        lightDarkMode={lightDarkMode}
        favorite={favorite}
        setFavorite={setFavorite}
        label={label}
        action={action}
      />
    );
  }
};
const ApplicationCard = styled(ApplicationCardRaw)`
  ${({ hideOpenButtons }) => {
    const {
      user: { lightDarkMode },
    } = useUser();
    if (hideOpenButtons === true) {
      return `&:hover { cursor: pointer; border-color: ${
        lightDarkMode === "dark" ? "#4d4d4d" : "#cccccc"
      };  background: ${lightDarkMode === "dark" ? "#333" : "#e6e6e6"};`;
    }
  }}
`;
export { ApplicationCard };

import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: ${({ bannerWidth }) => bannerWidth};
  overflow: hidden;
  border: 2px solid black;
  height: 195px;
  margin: 0 auto 10px auto;
`;

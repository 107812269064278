import React, { useState } from "react";
import styled from "styled-components";
import "styled-components/macro";
import { SideSheetHeader, SideSheetBody } from "components/SideSheet";
import { IconHelp, IconContentPaste, SearchInput } from "cdk-radial";
import { helpDocs } from "data";

const FeaturedHelpLink = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: 24px;
  svg {
    width: 40px;
    height: 40px;
  }
`;

const HelpRaw = ({ className, toggle }) => {
  const [value, setValue] = useState("");

  const handleChange = (searchValue) => {
    setValue(searchValue);
  };

  const topHelpDocs = helpDocs.sort((a, b) => {
    const intA = a.viewCount * 1;
    const intB = b.viewCount * 1;
    if (intA < intB) return 1;
    if (intA > intB) return -1;
    return 0;
  });

  return (
    <div className={className}>
      <SideSheetHeader title="Help" toggle={toggle} />
      <SideSheetBody>
        {/* <SearchInput
          clearButtonLabel="clearButtonLabel"
          isAlwaysExpanded={true}
          id="search-input"
          name="search-input"
          onChange={handleChange}
          placeholder="What do you need help with?"
          value={value}
        /> */}
        <FeaturedHelpLink>
          <IconHelp />
          <h4>Top help topics</h4>
        </FeaturedHelpLink>
        {topHelpDocs.map((item, index) => (
          <p key={`topHelp-${index}`}>{item.title}</p>
        ))}

        {/* <FeaturedHelpLink>
          <IconContentPaste />
          <div>User Guide</div>
        </FeaturedHelpLink> */}
      </SideSheetBody>
    </div>
  );
};

const Help = styled(HelpRaw)`
  font-family: Montserrat;
  h4 {
    margin-top: 24px;
  }
`;

export { Help };

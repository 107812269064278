import styled from "styled-components";

export const LearnMore = styled.button`
  position: absolute;
  right: 8px;
  bottom: 8px;
  font-size: 12px;
  border-radius: 36px;
  letter-spacing: 0.8px;
  font-weight: 600;
  font-family: Montserrat;
  border: 1px solid #fff;
  color: #fff;
  background: #000;
  padding: 4px 12px;
  text-transform: uppercase;
  &:hover {
    background: #fff;
    color: #000;
    cursor: pointer;
  }
`;

import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components";
import "styled-components/macro";
import { BaseButton, IconClose, BUTTON_SIZES, getHexToRgb } from "cdk-radial";

const CustomIconButton = styled(BaseButton)`
  border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
  &::after {
    border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
  }

  svg {
    fill: white;
  }
  &:hover {
    svg {
      fill: black;
    }
    background-color: ${({ theme }) =>
      `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.8)`};
  }
`;

const RIGHT = "8px";
const openSheet = keyframes`
  from {
    right: -500px;
  }
  to {
    right: 8px;
  }
`;
const closeSheet = keyframes`
  from {
    right: 8px;
  }
  to {
   right: -500px;
  }
`;

const SideSheetRaw = ({ className, children, open }) => {
  return (
    <div
      className={`${
        open === null ? "" : open === true ? "open" : "closed"
      } ${className}`}
    >
      {children}
    </div>
  );
};

const SideSheet = styled(SideSheetRaw)`
  position: absolute;
  right: 8px;
  width: 429px;
  top: 48px;
  bottom: 8px;
  background: #fff;
  overflow: scroll;
  display: none;

  border-radius: 16px;
  z-index: 2000;
  right: -500px;

  &.open {
    animation: ${openSheet} 0.15s ease-in-out 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    display: block;
  }
  &.closed {
    animation: ${closeSheet} 0.15s ease-in-out 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    display: block;
  }
  box-shadow: -5px 0px 15px -3px rgba(0, 0, 0, 0.5);
`;

const SideSheetHeaderRaw = ({ className, toggle, title }) => {
  return (
    <div className={className}>
      <h2>{title}</h2>
      <CustomIconButton
        icon={<IconClose />}
        size={BUTTON_SIZES.SMALL}
        text="Close"
        onClick={toggle}
        hideText
      />
    </div>
  );
};
const SideSheetHeader = styled(SideSheetHeaderRaw)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px 16px 0 0;
  background: #000;
  color: #fff;
  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
  padding: 14px 16px;
`;
const SideSheetBody = styled.div`
  padding: 16px;
`;

export { SideSheet, SideSheetHeader, SideSheetBody, CustomIconButton };

const notifications = [
  {
    id: "Au5X0NCu0stTAp_91OlrP",
    title: "Inspection Form Updates",
    source: "Service",
    body: "Overview of Inspection form upgrades and VCU changes tied to 99P code Overview of Inspection form upgrades and VCU changes tied to 99P code Overview of inspection.",
    timestamp: "4/17/2022 04:45:00",
    actions: [],
  },
  {
    id: "-p147c8LqtDWjUbCgXzUP",
    title: "Test Notification",
    source: "Service",
    body: "Test new Notification. Testing the new Notifications.",
    timestamp: "4/16/2022 11:23:00",
    actions: [],
  },
  {
    id: "8-3Q5pokcisMHZ3lLyNl9",
    title: "iPad Users Do Not Update",
    source: "Service",
    body: "iPad Users should not update to iPadOS 13.1",
    timestamp: "4/15/2022 00:01:00",
    actions: [],
  },
  {
    id: "1",
    title: "Notification Title",
    source: "DMS",
    body: "This is the text for the notification body.",
    timestamp: "3/29/2022 08:00:00",
    actions: [],
  },
  {
    id: "2",
    title: "Notification Title",
    source: "DMS",
    body: "This is the text for the notification body.",
    timestamp: "3/28/2022 05:30:00",
    actions: [],
  },
  {
    id: "3",
    title: "Notification Title",
    source: "DMS",
    body: "This is the text for the notification body.",
    timestamp: "3/27/2022 18:00:00",
    actions: [],
  },
  {
    id: "4",
    title: "Notification Title",
    source: "DMS",
    body: "This is the text for the notification body.",
    timestamp: "3/26/2022 09:05:00",
    actions: [],
  },
  {
    id: "5",
    title: "Notification Title",
    source: "DMS",
    body: "This is the text for the notification body.",
    timestamp: "3/25/2022 08:00:00",
    actions: [],
  },
  {
    id: "6",
    title: "Notification Title",
    source: "DMS",
    body: "This is the text for the notification body.",
    timestamp: "3/24/2022 05:30:00",
    actions: [],
  },
  {
    id: "7",
    title: "Notification Title",
    source: "DMS",
    body: "This is the text for the notification body.",
    timestamp: "3/23/2022 18:00:00",
    actions: [],
  },
  {
    id: "8",
    title: "Notification Title",
    source: "DMS",
    body: "This is the text for the notification body.",
    timestamp: "3/22/2022 09:05:00",
    actions: [],
  },
  {
    id: "9",
    title: "Notification Title",
    source: "DMS",
    body: "This is the text for the notification body.",
    timestamp: "3/21/2022 08:00:00",
    actions: [],
  },
  {
    id: "10",
    title: "Notification Title",
    source: "DMS",
    body: "This is the text for the notification body.",
    timestamp: "3/20/2022 05:30:00",
    actions: [],
  },
  {
    id: "11",
    title: "Notification Title",
    source: "DMS",
    body: "This is the text for the notification body.",
    timestamp: "3/19/2022 18:00:00",
    actions: [],
  },
  {
    id: "12",
    title: "Notification Title",
    source: "DMS",
    body: "This is the text for the notification body.",
    timestamp: "3/18/2022 09:05:00",
    actions: [],
  },
];

export { notifications };
